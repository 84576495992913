
import {of as observableOf, throwError as observableThrowError,
  Observable,
  throwError,
  of
} from 'rxjs';

import {tap, mergeMap, catchError, finalize} from 'rxjs/operators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import {
  ErrorManagerService
} from '../../../shared/services/errorManager.service';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  VolunteerService
} from '../../volunteers.service';
import {
  FicheEntretien,
  FicheEntretienOptions,
  FicheEntretienProduit,
  Panelist,
  Timeline,
  TimelineTypes,
  Type
} from '../../../types';
import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';
import * as _ from 'lodash';
import { UserService } from '../../../shared/services/user.service';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-fiche-entretien',
  templateUrl: 'ficheEntretien.component.html',
  styleUrls: ['./ficheEntretien.less']
})

export class FicheEntretienComponent implements OnInit, AfterViewInit {
  showSpinner: boolean;
  isEditable: boolean;
  isProduct: boolean;
  panelist: Panelist;
  ficheEntretienOptions: FicheEntretienOptions;
  ficheEntretien: FicheEntretien;
  productsList: Type[];
  dateApplication: NgbDateStruct;
  isActiveProduct: any = {};
  bridge: string;
  initTime = performance.now();

  constructor(
    private volunteerService: VolunteerService,
    private activatedRoute: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private errorManagerService: ErrorManagerService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.showSpinner = true;
    this.panelist = this.volunteerService.getPanelist();
    this.ficheEntretienOptions = new FicheEntretienOptions();
    this.activatedRoute.params.pipe(
      tap(p => this.bridge = _.get(p, 'bridgeNumber', '')),
      mergeMap(p => this.getFicheEntretien(p.idFiche, this.panelist.hub)),
      catchError(err => {
        this.goToGeneralInfo();
        this.errorManagerService.displayMessage(err.error.error, 'danger', {}, false);
        return observableThrowError(err);
      }),
      tap(fiche => this.initDate(fiche.dateApplication)),
      tap(fiche => {
        fiche.produits.map(p => this.isActiveProduct[p.id] = p.isActive);
      }),
      tap(fiche => this.ficheEntretien = fiche),
      tap(() => {
        if(this.ficheEntretien.typeIntervention) {
          this.isProduct = true;
        }
      }),
      tap(() => this.showSpinner = false),)
      .subscribe();
  }

  ngAfterViewInit() {
    const templateUrl = this.activatedRoute && this.activatedRoute.snapshot ? this.activatedRoute.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Volunteer Fiche Entretien', '', performance.now() - this.initTime, templateUrl);
  }

  initDate = (timestamp: number) => {
    if (timestamp) {
      let dateS = new Date(timestamp)
      this.dateApplication = {
        year: dateS.getFullYear(),
        month: dateS.getMonth() + 1,
        day: dateS.getDate()
      };
    }
  }

  onDateChanged(date: NgbDateStruct) {
    this.ficheEntretien.dateApplication = new Date(Date.UTC(date.year, date.month - 1, date.day)).getTime();
  }

  onClickProduct(product: Type) {
    if (this.isEditable) {
      let productFound: FicheEntretienProduit = this.ficheEntretien.produits.find(p => p.id === product.id);
      productFound.isActive = !productFound.isActive;
      this.isActiveProduct[productFound.id] = !this.isActiveProduct[productFound.id];
      this.isProduct = this.isOneProductChosen(this.ficheEntretien.produits);
    }
  }

  isOneProductChosen(products: FicheEntretienProduit[]): boolean {
    for (let p of products) {
      if (p.isActive) return true;
    }
    return false;
  }

  isProductName(product: Type): boolean {
    return FicheEntretienOptions.isProductName(product);
  }

  isShade(product: Type): boolean {
    return FicheEntretienOptions.isShade(product);
  }

  isStrength(product: Type): boolean {
    return FicheEntretienOptions.isStrength(product);
  }

  onTypeInterventionChanged(type: Type) {
    this.productsList = FicheEntretienOptions.getProducts(type);
    this.ficheEntretien.produits = this.productsList.map(produit => {
      this.isActiveProduct[produit.id] = true;
      return new FicheEntretienProduit(produit);
    });
    this.isProduct = true;
  }

  compareById(a: Type, b: Type) {
    return a && b && a.id == b.id;
  }

  getFicheEntretien(id: string, hub): Observable < FicheEntretien > {
    if (id === 'new') {
      this.isEditable = true;
      return observableOf(new FicheEntretien()).pipe(tap((f: FicheEntretien) => f.requestNumber = _.cloneDeep(this.bridge)));
    } else {
      this.isEditable = false;
      return this.volunteerService.getFicheEntretien(id, hub);
    }
  }

  isColoration(): boolean {
    return FicheEntretienOptions.isColoration(this.ficheEntretien.typeIntervention);
  }

  isDefrisage(): boolean {
    return FicheEntretienOptions.isDefrisage(this.ficheEntretien.typeIntervention);
  }

  save() {
    this.showSpinner = true;
    this.ficheEntretien.panelistNumber = this.panelist.panelistNumber;
    this.volunteerService.postFicheEntretien(this.panelist.panelistNumber, this.panelist.hub, this.ficheEntretien).pipe(
      catchError(err => {
        this.errorManagerService.displayMessage("UNKNOW_ERROR", "danger");
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
        tap(fiche => {
          this.panelist.timeline.push(this.volunteerService.getFicheLight(fiche))
          this.volunteerService.setPanelist(this.panelist);
        }),
        tap(() => this.goToGeneralInfo()),
        finalize(() => this.showSpinner = false)
      )
      .subscribe();
  }

  goToGeneralInfo() {
    let path =['volunteers', this.panelist.panelistNumber, this.panelist.hub, 'generalInformation'];
    if (this.bridge) {
      path.push(this.bridge);
    }
    this.router.navigate(path);
  }
}
