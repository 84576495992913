import {
  WorkflowService
} from '../../workflows.service';
import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';
import {
  Block,
  DNAComponent,
  Workflow,
  States
} from '../../../types';
import {
  DNATranslateService
} from '../../../shared/services/translate.service';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import * as _ from 'lodash';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-component-block-configuration',
  templateUrl: './componentConfiguration.component.html'
})

export class ComponentConfigurationBlockComponent implements OnInit, AfterViewInit {
  workflow: Workflow;
  block: Block;
  component: DNAComponent;
  inactive: boolean;
  languages: string[] = [];
  fieldsOfComponents: any[] = [];
  currentLanguage: string;
  showSpinner: boolean = true;
  initTime = performance.now();


  constructor(
    private DNATranslate: DNATranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private workflowService: WorkflowService
  ) {}

  ngOnInit() {
    this.workflow = _.cloneDeep(this.workflowService.workflow);

    if (this.workflowService.blockToEdit && this.workflowService.componentToRead) {
      this.initComponent(this.workflowService.componentToRead, this.workflowService.blockToEdit);
    } else {
      this.route.paramMap.subscribe(params => {
        let idInQuestionnaire = params.get('idInQuestionnaire');
        let idInBlock = params.get('idInBlock');
        let block = this.workflow.blocks.find(b => b.idInQuestionnaire === idInQuestionnaire);
        if (!block) {
          return this.router.navigate(['workflows', this.workflow.id]);
        }
        let component = block.components.find(c => c.idInBlock === idInBlock);
        if (!component) {
          return this.router.navigate(['workflows', this.workflow.id, 'blocks', block.id]);
        }
        this.initComponent(component, block);
      });
    }

    this.DNATranslate.getLanguages().then(
      request => this.languages = request
    );

    this.currentLanguage = this.DNATranslate.getLanguage();

    this.DNATranslate.onLangChange().subscribe((translation: any) => {
      this.currentLanguage = translation.lang;
    });
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Library Component Configuration', '', performance.now() - this.initTime, templateUrl);
  }

  initComponent(component: DNAComponent, block: Block) {
    this.block = block;
    this.component = component;
    this.inactive = !this.component.isEditable || this.workflow.state === States.Published;
    let translateComponent = this.DNATranslate.getTranslatablesFieldsOfComponent(this.component.type);
    this.fieldsOfComponents.push(translateComponent);
    this.showSpinner = false;
  }

  validate() {
    this.workflowService.workflow = this.workflow;
    this.workflowService.setComponentToRead(null);
    this.router.navigate(['workflows', this.workflow.id, 'editBlock']);
  }

  cancel() {
    this.workflowService.setComponentToRead(null);
    this.router.navigate(['workflows', this.workflow.id, 'editBlock']);
  }

}
