import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApplicationInsightsService } from '../../shared/services/applicationInsights.service';
import { Menu, User, UserWorkspace } from '../../types';
import { UserService } from '../../shared/services/user.service';
import { UtilService } from '../../shared/services/util.service';
import { DNATranslateService } from '../../shared/services/translate.service';

@Component({
  selector: 'dna-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit, AfterViewInit {
  route: any;
  sideMenu: Menu[];
  user: User;
  initTime = performance.now();
  userIsWorkspaceAdministrator: boolean = false;
  userIsAdministrator: boolean = false;
  userIsBlocks: boolean = false;

  constructor(
    private aiService: ApplicationInsightsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private utilService: UtilService,
    private translateService: DNATranslateService
  ) {}


  ngOnInit() {

    this.translateService.onLangChange().subscribe((translation: any) => {
      this.updateMenu();
    });

    this.user = this.userService.getUser();
    this.route = this.router.url;
    this.userIsAdministrator = this.userService.isAuthorized('DNA_ADMIN');
    this.userIsBlocks = this.userService.isAuthorized('DNA_BLOCK');
    this.userIsWorkspaceAdministrator = this.authorization();
    this.updateMenu();
  }

  updateMenu() {
    this.utilService.createMenuProfile(this.userIsAdministrator, this.userIsWorkspaceAdministrator, this.userIsBlocks).subscribe(
      (menus: Menu[]) => this.sideMenu = menus
    );
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Profile', '', performance.now() - this.initTime, templateUrl);
  }

  authorization() {
    if (this.userIsAdministrator) {
      return true;
    } else {
      let workspace = this.user.workspaces.find((workspace: UserWorkspace) => workspace.isAdmin);
      return workspace ? true : false;
    }
  }

  updateRoute() {
    this.route = this.router.url;
  }

}
