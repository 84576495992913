
import { of as observableOf, Observable } from 'rxjs';

import { catchError, tap, take } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import * as _ from 'lodash';
import { OnePagerService } from '@app/shared/services/one-pager.service';
import { OnePager, SimComp, CampaignOnePager } from '@app/types';
import { ErrorManagerService } from '@app/shared/services/errorManager.service';
import { UtilService } from '@app/shared/services/util.service';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '@app/shared/services/applicationInsights.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dna-one-pager-target-protocol',
  templateUrl: './one-pager-target-protocol.component.html',
  styleUrls: ['./one-pager-target-protocol.component.less']
})
export class OnePagerTargetProtocolComponent implements OnInit, AfterViewInit {

  campaign: CampaignOnePager;
  initialOnePager: OnePager;
  onePager: OnePager;
  targetForm: FormGroup;
  initialTargetForm: FormGroup;
  displayProductsTable = false;
  comparison = false;
  isChanged = false;
  initialized = false;
  couples: SimComp[];
  substrate = '';
  formulasName: string[];
  initTime = performance.now();

  constructor(
    private onePagerService: OnePagerService,
    private errorManagerService: ErrorManagerService,
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private utilService: UtilService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.init();
    this.onChange();
    this.initialTargetForm = _.cloneDeep(this.targetForm);
    this.targetForm = _.cloneDeep(this.initialTargetForm);
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign One Pager Target Protocol', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.onePager = this.onePagerService.getOnePager();
    this.initialOnePager = _.cloneDeep(this.onePager);
    this.campaign = this.onePagerService.getCampaign();
    this.comparison = _.get(this.campaign, 'formula.simultaneousComparison.isActive', false);

    if (!this.comparison) {
      this.formulasName = _.get(this.campaign, 'formula.listFormulas', []).map(formula => formula.name);
    }
    this.couples = _.get(this.campaign, 'formula.simultaneousComparison.couples', []).filter(couple => couple.isActive);

    if (!this.onePager.targetAssignment || this.onePager.targetAssignment.length === 0) {
      this.onePager.targetAssignment = [{
        target: '',
        volunteersNumber: 0
      }];
    }
    this.displayProductsTable = _.get(this.campaign, 'parameters.isRoutine', false);
    this.targetForm = this.initTargetForm(this.onePager);
    this.substrate = _.get(this.onePager, 'substrate.key', 'VOLUNTEERS');
  }

  reinitForm() {
    this.onePagerService.buildOnePagerTargetProtocol(this.onePager, this.campaign);
    this.onePagerService.buildOnePagerProductsTable(this.onePager, this.campaign);
    this.init();
    if (!_.isEqual(this.formatFormValues(this.initialTargetForm.value), this.formatFormValues(this.targetForm.value))) {
      this.isChanged = true;
      this.ref.detectChanges();
    }
  }

  catchError = (err) => {
    this.errorManagerService.catchError(err);
    this.errorManagerService.displayMessage('ON_ERROR_UPDATE', 'danger');
    return observableOf(this.onePager);
  }

  private initTargetForm(onePager: OnePager): FormGroup {
    //prevent rich text editor from triggering on change event on init
    const formattedProtocol = onePager.protocol.includes('<p>') ? onePager.protocol : `<p>${onePager.protocol}</p>`;
    return new FormGroup({
      targetAssignment: new FormArray(_.get(onePager, 'targetAssignment', []).map((ta) =>
        new FormGroup({
          target: new FormControl(ta.target),
          volunteersNumber: new FormControl(ta.volunteersNumber)
        })
      )),
      protocol: new FormControl(formattedProtocol),
      formulas: new FormGroup({
        reference: new FormArray(_.get(onePager, 'formulas.reference', []).map((ref) =>
          new FormGroup({
            routineName: new FormControl(ref.routineName),
            routineLabel: new FormControl(ref.routineLabel),
            formulaName: new FormControl(ref.formulaName),
            stepName: new FormControl(ref.stepName),
            lot: new FormControl(ref.lot),
            socle: new FormControl(ref.socle)
          })
        )),
        tested: new FormArray(_.get(onePager, 'formulas.tested', []).map((tes) =>
          new FormGroup({
            routineName: new FormControl(tes.routineName),
            routineLabel: new FormControl(tes.routineLabel),
            formulaName: new FormControl(tes.formulaName),
            stepName: new FormControl(tes.stepName),
            lot: new FormControl(tes.lot),
            socle: new FormControl(tes.socle)
          })
        ))
      }),
    })
  }

  private assignFormToOnePager = (form: any) => {
    this.onePager = Object.assign(this.onePager, form);
    this.onePagerService.setOnePager(this.onePager);
  }

  private updateOnePager = (): Observable<OnePager> => {
    this.errorManagerService.displayMessage('ON_SAVE_PROCESSING', 'info');
    return this.onePagerService.updateOnePager(this.onePager.id,  this.onePagerService.getElementToUpdate(this.initialOnePager, this.onePager)).pipe(take(1),
      tap(() => this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE')),
      catchError(this.catchError));
  }

  changeValue(object, evt, type, field) {
    switch (type) {
      case 'reference':
        const formTested = this.targetForm.get('formulas.tested').value;
        const indexTestedEquivalent = formTested.findIndex(ft => {
          return ft.campaignName === object.campaignName &&
            ft.routineName === object.routineName &&
            ft.formulaName === object.formulaName;
        });
        if (indexTestedEquivalent != -1) {
          field === 'lot' ?
            this.tested.at(indexTestedEquivalent).get('lot').setValue(evt.target.value) :
            this.tested.at(indexTestedEquivalent).get('socle').setValue(evt.target.value);
        }
        break;
      case 'tested':
        const formReference = this.targetForm.get('formulas.reference').value;
        const indexReferenceEquivalent = formReference.findIndex(ft => {
          return ft.campaignName === object.campaignName &&
            ft.routineName === object.routineName &&
            ft.formulaName === object.formulaName;
        });
        if (indexReferenceEquivalent != -1) {
          field === 'lot' ?
            this.reference.at(indexReferenceEquivalent).get('lot').setValue(evt.target.value) :
            this.reference.at(indexReferenceEquivalent).get('socle').setValue(evt.target.value);
        }
        break;
    }
  }

  // Reference
  get reference() {
    return this.targetForm.get('formulas.reference') as FormArray;
  }

  // Tested
  get tested() {
    return this.targetForm.get('formulas.tested') as FormArray;
  }

  /********** Item management **********/

  // Item
  get items() {
    return this.targetForm.get('targetAssignment') as FormArray;
  }

  addItem(item = {
    target: '',
    volunteersNumber: 0
  }): void {
    this.items.push(new FormGroup({
      target: new FormControl(item.target),
      volunteersNumber: new FormControl(item.volunteersNumber)
    }));
  }

  removeItem(index: number) {
    this.items.removeAt(index);
  }

  save(targetForm: FormGroup) {
    this.assignFormToOnePager(targetForm.value);
    this.updateOnePager().pipe(
      catchError(this.catchError))
      .subscribe((onePager: OnePager) => {
        this.onePager = onePager;
        this.initialOnePager = _.cloneDeep(this.onePager);
        this.onePagerService.setOnePager(this.onePager);
        this.initialTargetForm = _.cloneDeep(this.targetForm);
        this.isChanged = false;
        this.ref.detectChanges();
      });
  }

  onCancel() {
    this.targetForm = _.cloneDeep(this.initialTargetForm);
    this.isChanged = false;
  }

  onChange() {
    this.targetForm.valueChanges.subscribe((values) => {
      if (!_.isEqual(this.formatFormValues(this.initialTargetForm.value), this.formatFormValues(this.targetForm.value))) {
        this.isChanged = true;
        this.ref.detectChanges();
      }
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.utilService.canDeactivate(this.targetForm.value, this.initialTargetForm.value);
  }

  formatFormValues(formValues: any) {
    return {
      ...formValues,
      protocol: this.utilService.htmlToText(formValues.protocol)
    };
  }
}
