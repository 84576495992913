import {
  WorkflowService
} from '../../workflows.service';
import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  ParamMap,
  Router
} from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  NgForm
} from '@angular/forms';

import * as _ from 'lodash';

import {
  Block,
  BlockParameter,
  ChartType,
  DNAComponent,
  JsonPatch,
  States,
  Workflow
} from '../../../types';
import {
  ChartTypeService
} from '../../../shared/services/chartType.service';
import {
  DNATranslateService
} from '../../../shared/services/translate.service';
import {
  ErrorManagerService
} from '../../../shared/services/errorManager.service';
import { BlockService } from '../../../blocks/blocks.service';
import { flatMap, mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';


@Component({
  selector: 'dna-block-configuration',
  templateUrl: './blockConfiguration.component.html',
  styleUrls: ['./blockConfiguration.component.less']
})

export class BlockConfigurationComponent implements OnInit, AfterViewInit {
  block: Block;
  initialBlock: Block;
  blockForm: FormGroup;
  showSpinner: boolean = true;
  chartTypes: ChartType[];
  currentLanguage: string;
  fieldsOfComponents: any;
  inactive: boolean;
  indexBlock: number;
  languages: string[] = [];
  numberOfBlocks: number;
  workflow: Workflow;
  isCollapsedBlockName: boolean = true;
  isCollapsedBlockParameter: boolean = true;
  initTime = performance.now();

  constructor(
    private _fb: FormBuilder,
    private chartType: ChartTypeService,
    private DNATranslate: DNATranslateService,
    private aiService: ApplicationInsightsService,
    private errorManager: ErrorManagerService,
    private workflowService: WorkflowService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: DNATranslateService,
    private blockService: BlockService
  ) {}

  ngOnInit() {


    this.currentLanguage = this.DNATranslate.getLanguage();
    this.chartTypes = this.chartType.getChartType();
    this.workflow = _.cloneDeep(this.workflowService.workflow);

    this.inactive = this.workflow.state === States.Published;
    this.blockForm = this._fb.group({
      name: this._fb.group({
        english: {
          value: '',
          disabled: this.inactive
        },
        french: {
          value: '',
          disabled: this.inactive
        },
        japanese: {
          value: '',
          disabled: this.inactive
        },
        chinese: {
          value: '',
          disabled: this.inactive
        },
        portuguese: {
          value: '',
          disabled: this.inactive
        },
        spanish: {
          value: '',
          disabled: this.inactive
        }
      }),
      description: this._fb.group({
        english: {
          value: '',
          disabled: this.inactive
        },
        french: {
          value: '',
          disabled: this.inactive
        },
        japanese: {
          value: '',
          disabled: this.inactive
        },
        chinese: {
          value: '',
          disabled: this.inactive
        },
        portuguese: {
          value: '',
          disabled: this.inactive
        },
        spanish: {
          value: '',
          disabled: this.inactive
        }
      })
    });

    if (this.workflowService.blockToEdit) {
      this.initBlock(this.workflowService.blockToEdit);
    } else {
      this.route.paramMap.pipe(
        mergeMap((params: ParamMap) => this.blockService.getBlock(params.get('id'))),
        tap((block: Block) => {
          if (!block) {
            this.router.navigate(['workflows', this.workflow.id]);
          } else {
            this.initBlock(block);
          }

        })
      ).subscribe();
    }

    this.inactive = this.workflow.state === States.Published;


    this.DNATranslate.getLanguages().then(
      request => this.languages = request
    );

    this.DNATranslate.onLangChange().subscribe((translation: any) => {
      this.currentLanguage = translation.lang;
    });

    this.blockForm.valueChanges.subscribe((values: any) => {
      for (let property in values) {
        this.block[property] = values[property];
      }
    });

  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Library Block Configuration', '', performance.now() - this.initTime, templateUrl);
  }

  initBlock(block: Block) {
    this.block = block;
    if (!this.block.parameters) {
      this.block.parameters = new BlockParameter();
    }
    this.initialBlock = _.cloneDeep(this.block);
    this.numberOfBlocks = this.workflow.blocks.length == 0 ? 1 : this.workflow.blocks.length;

    this.fieldsOfComponents = {};
    this.fieldsOfComponents[0] = [];
    for (let indexComponent in this.block.components) {
      let component = this.block.components[indexComponent];
      let translateComponent = this.translateService.getTranslatablesFieldsOfComponent(component.type);
      this.fieldsOfComponents[0].push({
        indexComponent: indexComponent,
        translatableField: translateComponent
      });
    }

    this.setFormValues(block);
    this.showSpinner = false;
  }

  cancel() {
    this.workflowService.setBlockToEdit(null);
    this.router.navigate(['workflows', this.workflow.id]);
  }

  editComponentConfiguration(component: DNAComponent) {
    this.workflowService.setComponentToRead(component);
    this.router.navigate(['workflows', this.workflow.id, 'editComponent']);
  }

  setContextualBlock(isContextual: boolean, block: Block) {
    block.parameters.isContextual = isContextual;
    if (!block.parameters.isContextualPerFormula && !block.parameters.isContextualPerEval) {
      block.parameters.isContextualPerEval = true;
    }
  }

  setContextualEval(block: Block) {
    block.parameters.isContextualPerEval = !block.parameters.isContextualPerEval;
    block.parameters.isContextualPerFormula = !block.parameters.isContextualPerEval;
  }

  setContextualFormula(block: Block) {
    block.parameters.isContextualPerFormula = !block.parameters.isContextualPerFormula;
    block.parameters.isContextualPerEval = !block.parameters.isContextualPerFormula;
  }

  setFormValues(block: Block) {
    try {
      this.blockForm.setValue({
        name: block.name,
        description: block.description
      });
    } catch (error) {
      console.log(error);
    }
  }

  validate(form: NgForm) {
    if (!form.invalid) {
      let blockUpdated = false;
      if (!_.isEqual(this.block.parameters, this.initialBlock.parameters)) {
        this.workflowService.setBlockToEdit({
          ...this.workflowService.blockToEdit,
          parameters: this.block.parameters
        });
        blockUpdated = true;
      }
      if (!_.isEqual(this.block.name, this.initialBlock.name)) {
        this.workflowService.setBlockToEdit({
          ...this.workflowService.blockToEdit,
          name: this.block.name
        });
        blockUpdated = true;
      }
      if (!_.isEqual(this.block.description, this.initialBlock.description)) {
        this.workflowService.setBlockToEdit({
          ...this.workflowService.blockToEdit,
          description: this.block.description
        });
        blockUpdated = true;
      }
      if (!_.isEqual(this.block.mandatory, this.initialBlock.mandatory)) {
        this.workflowService.setBlockToEdit({
          ...this.workflowService.blockToEdit,
          mandatory: this.block.mandatory
        });
        blockUpdated = true;
      }
      // cas du linkRadioVersus
      if (!_.isEqual(this.block.components, this.initialBlock.components)) {
        this.block.components.forEach(component => {
          if (!_.isEqual(component, this.initialBlock.components.filter(initialComponent => initialComponent.id === component.id)[0])) {
            blockUpdated = true;
            this.workflowService.setBlockToEdit({
              ...this.workflowService.blockToEdit,
              components: this.workflowService.blockToEdit.components.map(blockComponent => {
                if (blockComponent.index === component.index) {
                  return component;
                }
                return blockComponent;
              })
            });
          }
        });
      }
      if (blockUpdated) {
        let indexBlock = 0;
        if (this.block.id) {
          indexBlock = this.workflow.blocks.findIndex(b => b.id === this.block.id);
        } else {
          indexBlock = this.workflow.blocks.findIndex(b => b.index === this.block.index);
        }

        if (indexBlock !== -1) {
          this.block = {...this.workflowService.blockToEdit};
          this.workflowService.workflow.blocks[indexBlock] = {...this.workflowService.blockToEdit};
          this.workflowService.setWorkflowLocal(this.workflowService.workflow);
        }
      }
      this.workflowService.setBlockToEdit(null);
      this.router.navigate(['workflows', this.workflow.id]);
    } else {
      this.errorManager.displayMessage('ON_ERROR_FORM', "danger");
    }
  }

}
