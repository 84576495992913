import {
  throwError as observableThrowError,
  Observable
  ,
  Subscription,
  throwError,
  of
} from 'rxjs';

import {catchError, tap, finalize} from 'rxjs/operators';
import {
  ActivatedRoute
} from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';

import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import * as moment from 'moment';

import {
  Buttons,
  Evaluation,
  Languages,
  TableHeader,
  ActionTypes,
  ActionBarButton,
  CampaignRawData,
  States
} from '../../../../../types';
import {
  CampaignService
} from '../../../../campaigns.service';
import {
  DNATranslateService
} from '../../../../../shared/services/translate.service';
import {
  UtilService
} from '../../../../../shared/services/util.service';
import {
  ErrorManagerService
} from '../../../../../shared/services/errorManager.service';

import * as _ from 'lodash';

import {environment} from '../../../../../../environments/environment';
import {
  UserService
} from '../../../../../shared/services/user.service';
import { ApplicationInsightsService } from '../../../../../shared/services/applicationInsights.service';


@Component({
  selector: 'dna-evaluations',
  templateUrl: './evaluations.component.html',
  styleUrls: ['./evaluations.component.less']
})
export class EvaluationsComponent implements OnInit, AfterViewInit {

  campaignRawData: CampaignRawData;
  containsPhotos: boolean = false;
  containsVideos: boolean = false;
  currentLanguage: Languages;
  evaluations: Evaluation[];
  idCampaign: string;
  listButtons$: Observable<Buttons[]>;
  showSpinner: boolean = false;
  subscribeLang: Subscription;
  tableHeaders$: Observable<TableHeader[]>;
  hasVolunteer: boolean = false;
  linkAuthorized: boolean = false;
  error: boolean = false;
  initTime = performance.now();

  constructor(
    private campaignService: CampaignService,
    private DNATranslate: DNATranslateService,
    private aiService: ApplicationInsightsService,
    private errorManager: ErrorManagerService,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign RawData', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.showSpinner = true;
    this.error = false;
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.idCampaign = this.route.parent.snapshot.paramMap.get('idCampaign');

    this.campaignService.getCampaignRawData(this.idCampaign).pipe(
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      tap((campaignRawData: CampaignRawData) => {
        if (campaignRawData) {
          // on exécute la méthode processCampaignRawData
          this.processCampaignRawData(campaignRawData);
        }
      }),
      finalize(() => this.showSpinner = false)
    ).subscribe();

    this.subscribeLang = this.DNATranslate.onLangChange().pipe(
      tap((translation: any) => this.currentLanguage = translation.lang),
      tap(() => this.tableHeaders$ = this.utilService.createHeadersEvaluation(this.campaignRawData, this.haveAtLeastOneUserAuthorized(this.campaignRawData))),
      tap(() => this.listButtons$ = !_.isUndefined(this.campaignRawData.evaluations) ? this.utilService.createButtonsEvaluations([this.containsPhotos, this.containsVideos]) : of()))
      .subscribe();
  }

  ngOnDestroy() {
    this.subscribeLang.unsubscribe();
  }

  private processCampaignRawData(campaignRawData: CampaignRawData) {
    this.containsPhotos = campaignRawData.containsPhotos ? campaignRawData.containsPhotos : true;
    this.containsVideos = campaignRawData.containsVideos ? campaignRawData.containsVideos : true;
    this.evaluations = this.campaignService.getEvaluations([campaignRawData]);
    this.listButtons$ = campaignRawData.evaluations ? this.utilService.createButtonsEvaluations([this.userService.isConfort(), this.containsPhotos, this.containsVideos]) : of();
    this.campaignRawData = campaignRawData;
    this.hasVolunteer = campaignRawData.isVolunteer;
    this.tableHeaders$ = this.utilService.createHeadersEvaluation(campaignRawData, this.haveAtLeastOneUserAuthorized(campaignRawData));
    this.campaignService.updateBreadCrumb(campaignRawData.name);
  }

  buttonAction(idButton: string, campaignRawData: CampaignRawData) {
    this.showSpinner = true;
    const zip = new JSZip();
    const dateStartCampaign = moment(campaignRawData.startDate).format("YYYYMMDD");
    let firstFolder = zip.folder(dateStartCampaign + "_" + campaignRawData.name);
    switch (idButton) {
      case 'EXPORT_CONFORT':
        this.showSpinner = true;
        const fileName = _.isEmpty(campaignRawData.requestNumber) ? campaignRawData.name : campaignRawData.requestNumber;
        this.campaignService.exportConfortPanel(campaignRawData.id).pipe(
          finalize(() => this.showSpinner = false)
        ).subscribe(
          blob => FileSaver.saveAs(blob, fileName + '-don.xlsx'),
          err => {
            console.log(err);
          }
        );
        break;
      case 'EXPORT_EXCEL':
        this.showSpinner = true;
        this.campaignService.getExportExcel(campaignRawData.id).then(() => this.showSpinner = false)
          .catch(() => this.showSpinner = false);
        break;
      case 'DOWNLOAD_IMAGES':
        if (campaignRawData.containsPhotos) {
          this.campaignService.downloadImg(campaignRawData.name, campaignRawData.workflows, campaignRawData.evaluations, firstFolder).pipe(
            finalize(() => {
              this.showSpinner = false;
            }))
            .subscribe(() => {
              zip.generateAsync({
                type: "blob"
              }).then((content: any) => {
                FileSaver.saveAs(content, campaignRawData.name + ".zip");
              });
            });
        }
        break;
      case 'DOWNLOAD_VIDEOS':
        if (campaignRawData.containsVideos) {
          this.campaignService.downloadVideo(campaignRawData.name, campaignRawData.workflows, campaignRawData.evaluations, firstFolder).pipe(
            finalize(() => {
              this.showSpinner = false;
            }))
            .subscribe(() => {
              zip.generateAsync({
                type: "blob"
              }).then((content: any) => {
                FileSaver.saveAs(content, campaignRawData.name + ".zip");
              });
            });
        }
        ;
        break;
    }
  }

  onClickActionButton(actionButton: ActionBarButton, idEval: string) {
    if (ActionTypes.GoToMobile === actionButton.id) {
      window.open(environment.mobile_url() + '/#/campaign/'
        + this.campaignRawData.id + '/evaluation/' + idEval);
    }
  }

  sort(evaluations: Evaluation[], headerId: string, reverse: boolean) {
    switch (headerId) {
      case 'ID':
        return this.utilService.sortListByType(evaluations, 'id', reverse);
      case 'WORKFLOW':
        return this.utilService.sortListByType(evaluations, 'questionnaireId', reverse);
      case 'FORMULA':
        return this.utilService.sortListByType(evaluations, 'formula.0.name', reverse);
      case 'USERS':
        return this.utilService.sortListByType(evaluations, 'users.0.key', reverse);
      case 'VOLUNTEER':
        return this.utilService.sortListByType(evaluations, 'volunteer.name', reverse);
      case 'PROGRESSION':
        return this.utilService.sortListByType(evaluations, 'progress', reverse);
    }
  }

  private haveAtLeastOneUserAuthorized(campaignRawData: CampaignRawData): boolean {
    return this.campaignService.getUserAuthorizedforCampaign(_.get(campaignRawData, 'users.accountables', []), this.userService.getUser());
  }

  isAuthorized(evaluation: Evaluation): boolean {
    return this.campaignService.getUserAuthorizedforEval(evaluation, this.userService.getUser());
  }
}
