import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';
import {
  ApplicationInsightsService
} from '../../shared/services/applicationInsights.service';
import {
  UserService
} from '../../shared/services/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'dna-dashboard',
  templateUrl: './dashboard.component.html'
})

export class DashboardComponent implements OnInit, AfterViewInit {
  displayBlockView: boolean = false;
  displayCampaignView: boolean = false;
  displayWorkflowView: boolean = false;
  initTime = performance.now();

  constructor(
    private aiService: ApplicationInsightsService,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}


  ngOnInit() {
    this.displayCampaignView = this.userService.isAuthorized('DNA_SCHEDULE');
    this.displayBlockView = this.userService.isAuthorized('DNA_BLOCK');
    this.displayWorkflowView = this.userService.isAuthorized('DNA_WORKFLOW');
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Dashboard',  '', performance.now() - this.initTime, templateUrl);
  }

}
