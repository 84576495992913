import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TargetKeys, CampaignMultiTargetProtocol, CampaignsMulti, Campaign, CampaignMultiReferenceTested, AnalyseTypes, Routine2, SextiFormula } from '../../../../types';
import * as _ from 'lodash';
import { MultiCampaignsService } from '../../../../multi-campaigns/multi-campaigns.service';
import { catchError, map, tap } from 'rxjs/operators';
import {
  forkJoin as observableForkJoin, of as observableOf
} from 'rxjs';
import { UtilService } from '../../../../shared/services/util.service';
import { OnePagerService } from '../../../../shared/services/one-pager.service';
import { DNATranslateService } from '../../../../shared/services/translate.service';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';



@Component({
  selector: 'dna-one-pager-multi-target-protocol',
  templateUrl: './one-pager-multi-target-protocol.component.html',
  styleUrls: ['./one-pager-multi-target-protocol.component.less']
})
export class OnePagerMultiTargetProtocolComponent implements OnInit, AfterViewInit {

  idCampaignMulti: string;
  campaignTarget: Campaign[] = [];
  campaignMulti: CampaignsMulti;
  targetHead: string[] = [];
  targetTraduction: any;
  widthTable: number = 0;
  showSpinner: boolean;
  error: boolean = false;
  targetProtocol: any;
  tab: CampaignMultiReferenceTested;
  campaignsMulti: CampaignsMulti;
  couplesLength: number = 0;
  analyseType: AnalyseTypes;
  routines: SextiFormula[];
  isRoutines: boolean = true;
  initTime = performance.now();

  constructor(
    private multiCampaignsService: MultiCampaignsService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private onePagerService: OnePagerService,
    private translateService: DNATranslateService
  ) { }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Multi Campaigns Details One Pager Target Protocol', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.showSpinner = true;
    this.error = false;
    this.idCampaignMulti = this.multiCampaignsService.getCampaignMultiId();
    this.multiCampaignsService.getCampaignMultiOnePagerTargetProtocol(this.idCampaignMulti).pipe(
      catchError(err => {
        this.error = true;
        return observableOf([]);
      }),
      tap((data: CampaignMultiTargetProtocol) => this.campaignTarget = this.utilService.formatTargetData(data.campaignsTarget ? data.campaignsTarget : [])),
      tap((data: CampaignMultiTargetProtocol) => this.campaignMulti = data.campaignsMulti),
      tap((data: CampaignMultiTargetProtocol) => this.targetProtocol = this.formatOnePagerData(data)),
      tap((data: CampaignMultiTargetProtocol) => this.isRoutines = this.hasRoutines(data)),
      tap((data: CampaignMultiTargetProtocol) => this.tab = this.formatCouples(this.onePagerService.getTestedReferenceTab(data.campaignsMulti, data.campaignsTarget))),
      tap((data: CampaignMultiTargetProtocol) => this.routines = this.isRoutines ? this.onePagerService.getRoutinesTab(data.campaignsTarget) : []),
      tap((data: CampaignMultiTargetProtocol) => this.analyseType = data.campaignsMulti.analyseType),
      tap(() => this.couplesLength = this.tab.couples.length),
      tap(() => this.targetTraduction = TargetKeys),
      tap(() => this.targetHead = this.utilService.getTargetHead(this.campaignTarget)),
      tap(() => this.widthTable = this.targetHead.length * 200 + 300),
      tap(() => this.showSpinner = false),
      tap(() => this.utilService.formatTable(this.targetHead, this.campaignTarget))
    ).subscribe();
  }

  formatCouples(tab: CampaignMultiReferenceTested) {
    let finalCouples = [];
    tab.couples.forEach(couple => {
      if (couple.tested.includes(',')) {
        let valuesTested = couple.tested.split(',');
        valuesTested.forEach(vt => {
          let newCouple = _.cloneDeep(couple);
          newCouple.tested = vt.trim();
          finalCouples.push(newCouple);
        })
      } else {
        finalCouples.push(couple)
      }
    })
    tab.couples = finalCouples;
    return tab;
  }

  /**
   * 21672 Format values from array to string
   * @param targetHead
   * @returns
   */
  formatValueHead(targetHead = []) {
    let observables = [];
    let resp = '';
    targetHead.filter(th => th.IsValue).forEach(th => {
      observables.push(this.translateService.translateMessage(th.Text));
    });
    observableForkJoin(observables).pipe(
      map(trads => resp = trads.join(', '))
    ).subscribe();
    return resp;
  }

  hasRoutines(campaignMultiTargetProtocol: CampaignMultiTargetProtocol) : boolean {
    return _.get(campaignMultiTargetProtocol, 'campaignsTarget[0].isRoutine', true);
  }

  formatOnePagerData(data: CampaignMultiTargetProtocol) {
    let result = {};
    data.campaignsTargetProtocolInfo.forEach(tp => {
      if (!_.isEmpty(tp.resources)) {
        result[tp.resources[0].id] = {
          volunteersNumber: _.get(tp.resources, '[0].targetAssignment[0].volunteersNumber', '0'),
          protocol: tp.resources[0].protocol,
          formulas: tp.resources[0].formulas
        };
      }
    });
    return result;
  }

  getFormulaByCampaign(idCampaign, formulas) {
    const campaignFormulas = _.get(this.campaignMulti, 'chosenFormulas', []).find(c => c.idCampaign === idCampaign);
    if (!campaignFormulas) {
      return formulas.map(f => f.name).join(", ");
    } else {
      const activeFormulas = formulas.filter(f => _.get(campaignFormulas, 'formulas', []).find(cf => cf.id === f.id && cf.isActive));
      return  activeFormulas.map(f => f.name).join(", ");
    }
  }
}

