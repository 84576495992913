import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ProtocolTraduction, ProtocolMulti, CampaignsMultiProtocol } from '../../../types';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { tap, flatMap, catchError } from 'rxjs/operators';
import { MultiCampaignsService } from '../../multi-campaigns.service';
import {of as observableOf } from 'rxjs';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';



@Component({
  selector: 'dna-protocol-multi',
  templateUrl: './protocol-multi.component.html',
  styleUrls: ['./protocol-multi.component.less']
})
export class ProtocolMultiComponent implements OnInit, AfterViewInit {

  idCampaigns: string[] = [];
  campaignProtocol: ProtocolMulti[] = [];
  campaignProtocolFormatted = {};
  protocoleHead: string[] = [];
  protocolTraduction: any;
  widthTable: number = 0;
  showSpinner: boolean;
  error: boolean;
  initTime = performance.now();

  constructor(
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private multiCampaignsService: MultiCampaignsService
  ) {}

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Multi Campaigns Details Protocol', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.showSpinner = true;
    this.error = false;
    this.route.parent.paramMap.pipe(
      flatMap((params: ParamMap) => this.multiCampaignsService.getCampaignMultiProtocol(params.get('idCampaignMulti'))),
      catchError(err => {
        this.error = true;
        return observableOf([]);
      }),
      tap((data: CampaignsMultiProtocol) => this.campaignProtocol = data.studies),
      tap((data: CampaignsMultiProtocol) => this.multiCampaignsService.hasNameChanged.next(data.name)),
      tap(() => this.protocolTraduction = ProtocolTraduction),
      tap(() => this.protocoleHead = this.getProtocoleHead(this.campaignProtocol)),
      tap(() => this.widthTable = this.protocoleHead.length * 200 + 300),
      tap(() => this.campaignProtocolFormatted = this.formatProtocolData(this.protocoleHead, this.campaignProtocol)),
      tap(() => this.showSpinner = false),
      tap(() => this.formatTable())
    ).subscribe();
  }

  formatTable() {
    if (!_.isEmpty(this.protocoleHead)) {
      setTimeout(() => {
        this.campaignProtocol.forEach(cp => {
          const tdHeight = document.getElementById('td_' + cp.id + '_0').clientHeight + 3;
          document.getElementById('td_' + cp.id).style.height = tdHeight + "px";
        });
      });
    }
  }

  formatProtocolData(protocoleHead, campaignProtocol) {
    let result = {};
    campaignProtocol.forEach(cp => {
      result[cp.id] = {};
      protocoleHead.forEach(head => {
          if (this.protocolTraduction[head].type === "array") {
            let values = [];
              let protocolHead = _.isNil(cp.protocol[head]) ? [] : cp.protocol[head];
              protocolHead.forEach((pr, index) => {
                head === 'timepointsInterval'
                  ? values.push("V" + (index+1) + ": "+ pr)
                  : values.push(pr.key);
              });
            result[cp.id][head] = {key: values};
          } else if (this.protocolTraduction[head].type === "object") {
            result[cp.id][head] = {key: cp.protocol[head] ? cp.protocol[head].value : ""};
          } else {
            if (_.isNil(cp.protocol) || cp.protocol[head] === null) {
              result[cp.id][head] = {key: ""};
            } else if (typeof cp.protocol[head] === 'string' || typeof cp.protocol[head] === 'number') {
              result[cp.id][head] = {key: "" + cp.protocol[head]};
            } else if (cp.protocol[head] === true) {
              result[cp.id][head] = {key: "YES", value: true};
            } else if (cp.protocol[head] === false) {
              result[cp.id][head] = {key: "NO", value: false};
            } else {
              result[cp.id][head] = cp.protocol[head];
            }
          }
      });
    });
    return result;
  }

  getProtocoleHead(campaignProtocol) {
    let head = [];
    campaignProtocol.forEach(cp => {
      if (_.get(cp, 'protocol', undefined)) {
        for (let protocolKeys of Object.keys(cp.protocol)) {
          if ((this.protocolTraduction[protocolKeys].type === "array" && !_.isEmpty(cp.protocol[protocolKeys]))
          || (cp.protocol[protocolKeys] && cp.protocol[protocolKeys] != 0 && cp.protocol[protocolKeys] != "")) {
            head.push(protocolKeys);
          }
        }
      }
    });
    return _.uniq(head);
  }
}
