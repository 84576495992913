<ngx-loading [show]="showSpinner"></ngx-loading>
<div class="ibox float-e-margins">
  <div class="ibox-title">
    <div class="row">
      <div class="col-12 col-md-4 my-auto">
        <h5>{{ "PROTOCOL" | translate }} - {{ "METIER_HAIR" | translate }}</h5>
      </div>
      <div class="col-12 col-md-8">
        <div class="float-end d-flex flex-row gap-1" *ngIf="isChangedProtocol && !publishedTemplate">
          <button class="btn btn-primary mr-2" type="button" (click)="onSubmit(protocolForm)" translate>SAVE</button>
          <button class="btn btn-default" type="button" (click)="onCancel()" translate>CANCEL</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="protocolForm" class="ibox-content">
    <form [formGroup]="protocolForm" name="protocolF" #protocolF="ngForm" novalidate>
      <div class="row">
        <div class="col-12 mb-3">
          <h4 translate>PROTOCOL_DESCRIPTION</h4>
          <textarea id="label-description" class="form-control" formControlName="description" placeholder="{{ 'ENTER_DESCRIPTION' | translate }}"
            name="textarea-description" rows="3"></textarea>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate>PROTOCOL_PRODUCTS_TYPES</h4>
          <tag-input inputClass="tagInputProtocol" formControlName="studies" [onlyFromAutocomplete]="true" name="tag-input-studies"
            displayBy="value" identifyBy="key" placeholder="+ {{ 'PROTOCOL_PRODUCTS_TYPES' | translate }}" [disable]="publishedTemplate"
            secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { object: 'PROTOCOL_PRODUCTS_TYPE' | translate | lowercase } }}">
            <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="studies" displayBy="value" identifyBy="key" [keepOpen]="false"
              [showDropdownIfEmpty]="true"></tag-input-dropdown>
          </tag-input>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate>PROTOCOL_TEST_TYPE</h4>
          <select id="label-test" class="form-control" formControlName="test" name="select-test" [compareWith]="compareKeyValue">
            <option *ngFor="let test of tests" [ngValue]="test">{{ test.value }}</option>
          </select>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 class="required" translate>METHOD</h4>
          <tag-input inputClass="tagInputMethod" formControlName="method" [onlyFromAutocomplete]="true" name="tag-input-methods"
            displayBy="value" identifyBy="key" placeholder="+ {{ 'METHOD' | translate }}"
            secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_MASC' | translate: { object: 'METHOD' | translate | lowercase } }}" [disable]="publishedTemplate"
            [ngClass]="{ 'isinvalid': getStatus('method') }"
            required>
            <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="methods" displayBy="value" identifyBy="key" [keepOpen]="false"
              [showDropdownIfEmpty]="true"></tag-input-dropdown>
          </tag-input>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 [ngClass]="{'required': this.campaign.actiview ? !!this.campaign.actiview.activityNumber : false}" translate>PROTOCOL_STUDY_TYPE</h4>
          <select id="label-study_type" class="form-control" formControlName="studyType" name="select-study_type" [compareWith]="compareCode" 
          [ngClass]="{ 'isinvalid': getStatus('studyType') }" required>
            <option *ngFor="let studyType of studyTypes" [ngValue]="studyType">{{ studyType.name[languageMap[currentLanguage]] || studyType.name['en'] }}</option>
          </select>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 [ngClass]="{'required': this.campaign.actiview ? !!this.campaign.actiview.activityNumber : false}" translate>ESTIMATED_CONTRIBUTION</h4>
          <input class="form-control" type="number" min="0" step="0.5" formControlName="estimatedContribution" name="estimatedContribution"
          placeholder="{{ 'ENTER_ESTIMATED_CONTRIBUTION' | translate }}" [ngClass]="{ 'is-invalid': protocolForm.controls.estimatedContribution.errors?.required }">
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate>PROTOCOL_METHODS</h4>
          <tag-input inputClass="tagInputProtocol" formControlName="protocol_methods" [onlyFromAutocomplete]="true" name="tag-input-protocol_methods"
            displayBy="value" identifyBy="key" placeholder="+ {{ 'PROTOCOL_METHOD' | translate }}"
            [disable]="publishedTemplate" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_FEM' | translate: { object: 'PROTOCOL_METHOD' | translate | lowercase } }}">
            <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="protocol_methods" displayBy="value" identifyBy="key" [keepOpen]="false"
              [showDropdownIfEmpty]="true"></tag-input-dropdown>
          </tag-input>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate>RESEARCH_AXES</h4>
          <select id="label-research-axes" class="form-control" formControlName="researchAxes" name="select-research-axes" [compareWith]="compareCode">
            <option *ngFor="let axe of researchAxes" [ngValue]="axe">{{ axe.name[languageMap[currentLanguage]] || axe.name['en'] }}</option>
          </select>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate>PROTOCOL_SCALE_TYPES</h4>
          <tag-input inputClass="tagInputProtocol" formControlName="scales" [onlyFromAutocomplete]="true" name="tag-input-scales" displayBy="value"
            identifyBy="key" placeholder="+ {{ 'PROTOCOL_SCALE_TYPE' | translate }}" secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_FEM' | translate: { object: 'PROTOCOL_SCALE_TYPE' | translate | lowercase } }}">
            <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="scales" displayBy="value" identifyBy="key" [keepOpen]="false"
              [showDropdownIfEmpty]="true"></tag-input-dropdown>
          </tag-input>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate>PROTOCOL_SUBSTRATE</h4>
          <select id="label-substrate" class="form-control" formControlName="substrate" name="select-substrate" [compareWith]="compareKeyValue">
            <option *ngFor="let substrate of substrates" [ngValue]="substrate">{{ substrate.value }}</option>
          </select>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate>PROTOCOL_APPLICATION_AREAS</h4>
          <tag-input inputClass="tagInputProtocol" formControlName="applicationAreas" [onlyFromAutocomplete]="true"
            name="tag-input-application-areas" displayBy="value" identifyBy="key" placeholder="+ {{ 'PROTOCOL_APPLICATION_AREA' | translate }}"
            secondaryPlaceholder="{{ 'ENTER_OBJECT_NAME_FEM' | translate: { object: 'PROTOCOL_APPLICATION_AREA' | translate | lowercase } }}">
            <tag-input-dropdown [appendToBody]="false" [autocompleteItems]="applicationAreas" displayBy="value" identifyBy="key" [keepOpen]="false"
              [showDropdownIfEmpty]="true"></tag-input-dropdown>
          </tag-input>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate>HAIR_SAMPLING</h4>
          <div class="row">
            <div class="col-12 col-md-7">
              <label translate>ADD_HAIR_SAMPLING</label>
            </div>
            <div class="col-12 col-md-5">
              <div class="float-left form-check form-check-inline">
                <input id="label-add-hairSampling" class="form-check-input" type="radio" formControlName="hairSampling" [value]="true" name="hairSampling">
                <label class="form-check-label" for="label-add-hairSampling" translate>YES</label>
              </div>
              <div class="float-left form-check form-check-inline">
                <input id="label-not-add-hairSampling" class="form-check-input" type="radio" formControlName="hairSampling" [value]="false"
                  name="hairSampling">
                <label class="form-check-label" for="label-not-add-hairSampling" translate>NO</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <h4 translate>PROTOCOL_APPLICATION_TYPE</h4>
          <div class="row">
            <div class="col-12 col-md-7">
              <label translate>PROTOCOL_SIMULTANEOUS_APPLICATION</label>
            </div>
            <div class="col-12 col-md-5">
              <div class="float-left form-check form-check-inline">
                <input id="label-is-simultaneaous" class="form-check-input" type="radio" formControlName="simultaneousApplication" [value]="true"
                  name="simultaneousApplication">
                <label class="form-check-label" for="label-is-simultaneaous" translate>YES</label>
              </div>
              <div class="float-left form-check form-check-inline">
                <input id="label-is-not-simultaneaous" class="form-check-input" type="radio" formControlName="simultaneousApplication" [value]="false"
                  name="simultaneousApplication">
                <label class="form-check-label" for="label-is-not-simultaneaous" translate>NO</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-7">
              <label translate>PROTOCOL_CONTROL_LOCK</label>
            </div>
            <div class="col-12 col-md-5">
              <div class="float-left form-check form-check-inline">
                <input id="label-use-control-lock" class="form-check-input" type="radio" formControlName="controlLock" [value]="true" name="controlLock">
                <label class="form-check-label" for="label-use-control-lock" translate>YES</label>
              </div>
              <div class="float-left form-check form-check-inline">
                <input id="label-use-not-control-lock" class="form-check-input" type="radio" formControlName="controlLock" [value]="false"
                  name="controlLock">
                <label class="form-check-label" for="label-use-not-control-lock" translate>NO</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="mb-3 text-center row">
          <div class="col-12 col-md-4">
            <div class="mb-2">
              <img src="/assets/cercle_uni_color.png" alt="Entire head" width="150">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <input id="label-entire-head" class="custom-radio" type="radio" formControlName="applicationType" [checked]="campaignProtocol.protocol.applicationType?.key === applicationTypes.EntireHead.key"
                [value]="applicationTypes.EntireHead" name="applicationType">
              <label for="label-entire-head" translate>{{applicationTypes.EntireHead.key}}</label>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="mb-2">
              <img src="/assets/cercle_bi_color.png" alt="Half-head" width="150">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <input id="label-half-head" class="custom-radio" type="radio" formControlName="applicationType" [checked]="campaignProtocol.protocol.applicationType?.key === applicationTypes.HalfHead.key"
                [value]="applicationTypes.HalfHead" name="applicationType">
              <label for="label-half-head" translate>{{applicationTypes.HalfHead.key}}</label>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="mb-2">
              <img src="/assets/cercle_tri_color.png" alt="Three routines" width="150">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <input id="label-three-routines" class="custom-radio" type="radio" formControlName="applicationType" [checked]="campaignProtocol.protocol.applicationType?.key === applicationTypes.ThreeRoutines.key"
                [value]="applicationTypes.ThreeRoutines" name="applicationType">
              <label for="label-three-routines" translate>{{applicationTypes.ThreeRoutines.key}}</label>
            </div>
          </div>
        </div>
        <div class="mb-3 text-center row">
          <div class="col-12 col-md-6">
            <div class="mb-2">
              <img src="/assets/cercle_nuque.png" alt="Two routines in neck" width="150">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <input id="label-two-routines-neck" class="custom-radio" type="radio" formControlName="applicationType" [checked]="campaignProtocol.protocol.applicationType?.key === applicationTypes.TwoRoutinesNeck.key"
                [value]="applicationTypes.TwoRoutinesNeck" name="applicationType">
              <label for="label-two-routines-neck" translate>{{applicationTypes.TwoRoutinesNeck.key}}</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-2">
              <img src="/assets/cercle_nuque_bande.png" alt="Two routines in neck + strip" width="150">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <input id="label-two-routines-neck-strip" class="custom-radio" type="radio" formControlName="applicationType" [checked]="campaignProtocol.protocol.applicationType?.key === applicationTypes.TwoRoutinesNeck_Strip.key"
                [value]="applicationTypes.TwoRoutinesNeck_Strip" name="applicationType">
              <label for="label-two-routines-neck-strip" translate>{{applicationTypes.TwoRoutinesNeck_Strip.key}}</label>
            </div>
          </div>
        </div>
        <div class="mb-3 text-center row">
          <div class="col-12 col-md-6">
            <div class="mb-2">
              <img src="/assets/cercle_trois_routines.png" alt="Three routines in neck" width="150">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <input id="label-three-routines-neck" class="custom-radio" type="radio" formControlName="applicationType" [checked]="campaignProtocol.protocol.applicationType?.key === applicationTypes.ThreeRoutinesNeck.key"
                [value]="applicationTypes.ThreeRoutinesNeck" name="applicationType">
              <label for="label-three-routines-neck" translate>{{applicationTypes.ThreeRoutinesNeck.key}}</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="mb-2">
              <img src="/assets/cercle_trois_quarts.png" alt="Three quarters - One quarter" width="150">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <input id="label-three-quarters" class="custom-radio" type="radio" formControlName="applicationType" [checked]="campaignProtocol.protocol.applicationType?.key === applicationTypes.ThreeQuarters_OneQuarter.key"
                [value]="applicationTypes.ThreeQuarters_OneQuarter" name="applicationType">
              <label for="label-three-quarters" translate>{{applicationTypes.ThreeQuarters_OneQuarter.key}}</label>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="mb-2">
              <img src="/assets/cercle_demi_tetes.png" alt="Half-head + clipped lock" width="150">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <input id="label-half-head-clipped-lock" class="custom-radio" type="radio" formControlName="applicationType" [checked]="campaignProtocol.protocol.applicationType?.key === applicationTypes.HalfHead_ClippedLock.key"
                [value]="applicationTypes.HalfHead_ClippedLock" name="applicationType">
              <label for="label-half-head-clipped-lock" translate>{{applicationTypes.HalfHead_ClippedLock.key}}</label>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="mb-2">
              <img src="/assets/cercle_demi_tetes.png" alt="Half-head + clipped lock hot" width="150">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <input id="label-half-head-clipped-lock-hot" class="custom-radio" type="radio" formControlName="applicationType" [checked]="campaignProtocol.protocol.applicationType?.key === applicationTypes.HalfHead_ClippedLockHot.key"
                [value]="applicationTypes.HalfHead_ClippedLockHot" name="applicationType">
              <label for="label-half-head-clipped-lock" translate>{{applicationTypes.HalfHead_ClippedLockHot.key}}</label>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="mb-2">
              <img src="/assets/cercle_meche_nuque.png" alt="clipped lock hot" width="150">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <input id="label-clipped-lock-hot" class="custom-radio" type="radio" formControlName="applicationType" [checked]="campaignProtocol.protocol.applicationType?.key === applicationTypes.ClippedLock.key"
                [value]="applicationTypes.ClippedLock" name="applicationType">
              <label for="label-clipped-lock" translate>{{applicationTypes.ClippedLock.key}}</label>
            </div>
          </div>
        </div>
        <div class="mb-3 text-center row">
          <div class="col-12 col-md-6">
              <div class="mb-2">
                <img src="/assets/cercle_3R_devant_derriere.png" alt="3R devant derrière" width="150">
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center">
                <input id="label-3R-front-back" class="custom-radio" type="radio" formControlName="applicationType" [checked]="campaignProtocol.protocol.applicationType?.key === applicationTypes.ThreeRoutinesFrontBack.key"
                  [value]="applicationTypes.ThreeRoutinesFrontBack" name="applicationType">
                <label for="label-3R-front-back" translate>{{applicationTypes.ThreeRoutinesFrontBack.key}}</label>
              </div>
            </div>
          <div class="col-12 col-md-6">
            <div class="mb-2">
              <img src="/assets/cercle_3R_gauche_droite.png" alt="3R gauche droite" width="150">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <input id="label-3R-left-right" class="custom-radio" type="radio" formControlName="applicationType" [checked]="campaignProtocol.protocol.applicationType?.key === applicationTypes.ThreeRoutinesLeftRight.key"
                [value]="applicationTypes.ThreeRoutinesLeftRight" name="applicationType">
              <label for="label-3R-left-right" translate>{{applicationTypes.ThreeRoutinesLeftRight.key}}</label>
            </div>
          </div>
        </div>
        <div class="mb-3 text-center row">
            <div class="col-12 col-md-6">
              <div class="mb-2">
                <img src="/assets/cercle_3R_hot_cold_swatches.png" alt="3R cold swatches" width="150">
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center">
                <input id="label-3R-cold-swatches" class="custom-radio" type="radio" formControlName="applicationType" [checked]="campaignProtocol.protocol.applicationType?.key === applicationTypes.ThreeRoutinesColdSwatches.key"
                  [value]="applicationTypes.ThreeRoutinesColdSwatches" name="applicationType">
                <label for="label-3R-left-right" translate>{{applicationTypes.ThreeRoutinesColdSwatches.key}}</label>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="mb-2">
                <img src="/assets/cercle_3R_hot_cold_swatches.png" alt="3R hot swatches" width="150">
              </div>
              <div class="d-flex flex-column justify-content-center align-items-center">
                <input id="label-3R-hot-swatches" class="custom-radio" type="radio" formControlName="applicationType" [checked]="campaignProtocol.protocol.applicationType?.key === applicationTypes.ThreeRoutinesHotSwatches.key"
                  [value]="applicationTypes.ThreeRoutinesHotSwatches" name="applicationType">
                <label for="label-3R-front-back" translate>{{applicationTypes.ThreeRoutinesHotSwatches.key}}</label>
              </div>
            </div>
          </div>
      </div>
      <div>
        <h4 translate>PROTOCOL_APPLICATION_ORDER</h4>
        <div class="mb-3 text-center row">
          <div class="col-12 col-md-4">
            <div class="mb-3">
              <img src="/assets/clockwise.png" alt="Clockwise" width="150">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <input id="label-clockwise" class="custom-radio" type="radio" formControlName="applicationOrder" [checked]="campaignProtocol.protocol.applicationOrder?.key === applicationOrders.clockwise.key"
                [value]="applicationOrders.clockwise" name="applicationOrder">
              <label for="label-clockwise" translate>{{applicationOrders.clockwise.key}}</label>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="mb-3">
              <img src="/assets/counterclockwise.png" alt="Counterclockwise" width="150">
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <input id="label-counterclockwise" class="custom-radio" type="radio" formControlName="applicationOrder" [checked]="campaignProtocol.protocol.applicationOrder?.key === applicationOrders.counterclockwise.key"
                [value]="applicationOrders.counterclockwise" name="applicationOrder">
              <label for="label-counterclockwise" translate>{{applicationOrders.counterclockwise.key}}</label>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="mb-3">
              <i class="fa fa-times icon-order" aria-hidden="true"></i>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <input id="label-none" class="custom-radio" type="radio" formControlName="applicationOrder" [checked]="campaignProtocol.protocol.applicationOrder?.key === applicationOrders.none.key"
                [value]="applicationOrders.none" name="applicationOrder">
              <label for="label-none" translate>{{applicationOrders.none.key}}</label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
