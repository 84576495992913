import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-no-workspaces',
  templateUrl: './no-workspaces.component.html',
  styleUrls: ['./no-workspaces.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class NoWorkspacesComponent implements OnInit, AfterViewInit {
  initTime = performance.now();

  constructor(private route: ActivatedRoute, private aiService: ApplicationInsightsService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('NoWorkspaces', '', performance.now() - this.initTime, templateUrl);
  }

}
