import { ErrorManagerService } from './../../../../shared/services/errorManager.service';
import { MultiCampaignsService } from './../../../multi-campaigns.service';
import { DNATranslateService } from './../../../../shared/services/translate.service';
import { Observable, throwError } from 'rxjs';
import { UtilService } from './../../../../shared/services/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Translatable, DescriptorGroup, CampaignMultiDescriptor, Descriptor, DNATypes, TableHeader, DNAComponent } from './../../../../types';
import { ComponentModalEditNameComponent } from './../../../../blocks/detail/component/edit-name/component-modal-edit-name.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { tap, catchError, take } from 'rxjs/operators';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';

@Component({
    selector: 'dna-descriptors-multi-edit',
    templateUrl: './descriptor-multi-edit.component.html',
    styleUrls: ['./descriptor-multi-edit.component.less']
})
export class DescriptorMultiEditComponent implements OnInit, AfterViewInit {
    descriptorGroup: DescriptorGroup;
    allDescriptors: Descriptor[] = [];
    othersDescriptors: Descriptor[] = [];
    multiCampaign: CampaignMultiDescriptor;
    multiCampaignUntouched: CampaignMultiDescriptor;
    isUpdated: boolean = false;
    tableHeaders$: Observable<TableHeader[]>;
    showSpinner: boolean;
    descriptorGroupName: Translatable = new Translatable("");
  initTime = performance.now();

    constructor(
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private router: Router,
        private aiService: ApplicationInsightsService,
        private multiCampaignsService: MultiCampaignsService,
        private utilService: UtilService,
        private translateService: DNATranslateService,
        private errorManagerService: ErrorManagerService
    ) { }

    ngOnInit() {
        this.multiCampaign = this.multiCampaignsService.getLocalCampaignMultiDescriptor();
        if (!this.multiCampaign.descriptorsGroups) {
            this.multiCampaign.descriptorsGroups = [];
        }
        this.multiCampaignUntouched = _.cloneDeep(this.multiCampaign);
        this.tableHeaders$ = this.utilService.createHeaders(DNATypes.Descriptors);
        this.init();
    }

    ngAfterViewInit() {
      const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
      this.aiService.logPageView('Multi Campaigns Details Edit Descriptor', '', performance.now() - this.initTime, templateUrl);
    }

    init() {
        let id = this.route.snapshot.paramMap.get('idDescriptor');
        if (id === "new") {
            this.descriptorGroup = new DescriptorGroup("");
            this.descriptorGroup.id = this.multiCampaignsService.generateId(this.multiCampaign.descriptorsGroups);
            this.multiCampaign.descriptorsGroups.push(this.descriptorGroup);
        }
        else {
            this.descriptorGroup = this.multiCampaign.descriptorsGroups.find(d => d.id === id);
            if (!this.descriptorGroup) {
                this.errorManagerService.displayMessage("IMPOSSIBLE_TO_FIND_DESCRIPTOR",'danger')
                this.back();
            }
        }
        this.allDescriptors = this.multiCampaignsService.initAllDescriptors(this.multiCampaign);
        this.updateDescriptors();
    }

    removeDescriptor(index: number) {
        this.descriptorGroup.descriptors.splice(index, 1);
        this.updateDescriptors();
        this.isUpdated = true;
    }

    addDescriptor(data: Descriptor) {
        this.descriptorGroupName = this.multiCampaignsService.updateDynamicallyNameGroup(this.descriptorGroup, data.name, _.get(data, 'blockSuffix', ''));
        // this.multiCampaignsService.updateDinamicallyNameGroup(this.descriptorGroup, data.name, [data.blockSuffix]);
        this.multiCampaignsService.pushDescriptor(this.descriptorGroup, data);
        this.updateDescriptors();
        this.isUpdated = true;
    }

    updateDescriptors() {
        const nbVisits = this.multiCampaign.studies.map(c => c.nbVisits);
        this.othersDescriptors = this.multiCampaignsService.filterDescriptorsAlreadyAdded(this.allDescriptors, this.multiCampaign.descriptorsGroups);
        this.othersDescriptors = this.multiCampaignsService.filterDescriptorsInGroup(this.othersDescriptors, this.descriptorGroup);
        this.multiCampaignsService.updatePertinance(this.allDescriptors, this.descriptorGroup.descriptors, this.multiCampaign, nbVisits.some(v => v > 1));
        this.multiCampaignsService.updatePertinance(this.descriptorGroup.descriptors, this.descriptorGroup.descriptors, this.multiCampaign, nbVisits.some(v => v > 1));
        this.sort(this.othersDescriptors, 'PERTINANCE', true);
    }

    openModalEditComponentName() {
        const modalOption: NgbModalOptions = {
            backdrop: "static",
            keyboard: false,
            size: "lg"
        };
        const modal = this.modalService.open(ComponentModalEditNameComponent, modalOption);
        modal.componentInstance.inactive = false;
        modal.componentInstance.label = _.cloneDeep(this.descriptorGroupName);
        modal.result.then(
            (translatedLabel: Translatable) => {
                if (this.descriptorGroupName !== translatedLabel) {
                    this.isUpdated = true;
                    this.descriptorGroupName = translatedLabel;
                }
            },
            (reason) => { }
        );
    }

    back() {
        this.router.navigate(['multi-campaigns', this.multiCampaign.id, 'descriptors', 'list'])
    }

    save() {
        if (this.descriptorGroup.descriptors.length === 0) {
            this.errorManagerService.displayMessage('ON_ERROR_EMPTY_DESCRIPTOR_GROUP', 'danger');
            return;
        }
        this.showSpinner = true;
        this.descriptorGroup.name = this.descriptorGroupName;
        this.multiCampaignsService.putCampaignMultiDescriptor(this.multiCampaign).pipe(
            tap(() => this.multiCampaignsService.setLocalCampaignMultiDescriptor(this.multiCampaign)),
            tap(() => this.showSpinner = false),
            tap(() => this.back()),
            take(1),
            catchError(err => {
                this.showSpinner = false;
                this.errorManagerService.displayMessage('UNKNOW_ERROR', 'danger');
                return throwError(err);
            })
        ).subscribe();

    }

    cancel() {
        this.multiCampaign = _.cloneDeep(this.multiCampaignUntouched);
        this.init();
        this.isUpdated = false;
    }

    sort(descriptors: Descriptor[] = [], idHeader: string, reverse: boolean = false) {
        switch (idHeader) {
            case 'STUDY':
                return this.utilService.sortListByType(descriptors, 'campaignName', reverse);
            case 'WORKFLOW':
                return this.utilService.sortListByType(descriptors, 'workflowName', reverse, true, this.translateService.getLanguage());
            case 'BLOCK':
                return this.utilService.sortListByType(descriptors, 'blockName', reverse, true, this.translateService.getLanguage());
            case 'DESCRIPTOR':
                return this.utilService.sortListByType(descriptors, 'name', reverse, true, this.translateService.getLanguage());
            case 'SCALE':
                return this.utilService.sortListByType(descriptors, 'scaleDetail', reverse);
            case 'PERTINANCE':
                return this.utilService.sortListByType(descriptors, 'pertinance', reverse);
        }
    }

    canDeactivate(): boolean {
        return true;
    }

}
