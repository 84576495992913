import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { VolunteerService } from '../../../volunteers/volunteers.service';
import { Hubs, Panelist, Hub, HubById } from '../../../types';
import { ReferenceTypeService } from '../../../shared/services/reference-type.service';
import * as _ from 'lodash';
import { FilterService } from '../../../shared/services/filter.service';
import { Observable } from 'rxjs';
import * as Excel from 'exceljs';
import { DateService } from '../../../shared/services/date.service';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

interface AttributeDetail {
  Code: string;
  Text: string;
  IsValue: boolean;
}

interface AttributeData {
  AttributeName: string;
  AttributeType: number;
  Value: string | null;
  AttributeDetails: AttributeDetail[];
}

interface ClassificationData {
  characterisationData: {
    AttributeData: AttributeData[];
    metier: {
      id: string;
    };
  };
  createdOn: number;
}

@Component({
  selector: 'dna-volunteers-management',
  templateUrl: './volunteers-management.component.html',
  styleUrls: ['./volunteers-management.component.css']
})
export class VolunteersManagementComponent implements OnInit, AfterViewInit {
  volunteersForm: FormGroup;
  volunteers: Panelist[] = [];
  countries: Hubs[] = [];
  selectedCountry: Hub;
  hub: HubById;
  filter: any;
  placeholderText = 'add volunteer';
  loading = false;
  initTime = performance.now();

  constructor(
    private fb: FormBuilder,
    private volunteerService: VolunteerService,
    private referenceTypeService: ReferenceTypeService,
    private filterService: FilterService,
    private appInsightsService: ApplicationInsightsService,
    private route: ActivatedRoute,
    private dateService: DateService
  ) { }

  ngOnInit(): void {
    this.countries = this.referenceTypeService.getHubs();
    this.selectedCountry = this.countries[2];
    this.filter = _.cloneDeep(this.filterService.getFilter(this.selectedCountry).panelists);
    delete this.filter.limit;
    delete this.filter.page;

    this.volunteersForm = this.fb.group({
      volunteerList: [[]],
      extraction: [false],
      deletion: [false]
    });

  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.appInsightsService.logPageView('Profile Volunteers Management', '', performance.now() - this.initTime, templateUrl);
  }

  public requestAutocompleteItems = (panelistNumber: string): Observable<any> => {
    this.filter = { ...this.filter, hub: this.referenceTypeService.getHubByIdFromHub(this.selectedCountry), panelistNumber: panelistNumber, subclass: 'arcs', isPanelist: true };
    return this.volunteerService.getPanelists(this.filter)
      .pipe(map(items => items.list.map(item => item)));
  }

  selectCountry(country: Hub): void {
    this.selectedCountry = country;
    this.hub = this.referenceTypeService.getHubByIdFromHub(country);
    this.filter = _.cloneDeep(this.filterService.getFilter(this.selectedCountry).panelists);
  }

  get isCountrySelectionDisabled(): boolean {
    return this.volunteersForm.get('volunteerList').value.length > 0;
  }

  get isSubmitDisabled(): boolean {
    const volunteerList = this.volunteersForm.get('volunteerList').value;
    const extraction = this.volunteersForm.get('extraction').value;
    const deletion = this.volunteersForm.get('deletion').value;
    return !(volunteerList.length > 0 && (extraction || deletion));
  }

  onSubmit() {
    this.hub = this.referenceTypeService.getHubByIdFromHub(this.selectedCountry);
    if (this.volunteersForm.get('extraction').value) {
      this.retrieveAndDownloadPanelistsData();
    }
  }

  async retrieveAndDownloadPanelistsData(): Promise<void> {
    const selectedVolunteers = this.volunteersForm.get('volunteerList').value;
    const selectedPanelistsIds = selectedVolunteers.map(volunteer => volunteer.panelistNumber);

    if (selectedPanelistsIds.length > 0) {
      this.loading = true;
      const downloadPromises: Array<Promise<{ panelistData: any[], panelistId: string } | null>> = selectedPanelistsIds.map(panelistId =>
        this.volunteerService.exportPanelistsById({ panelistIds: [panelistId] }, 'arcs', this.hub).toPromise()
          .then(panelistData => ({ panelistData: panelistData.list, panelistId } as { panelistData: any[], panelistId: string }))
          .catch(error => {
            console.error(`Error retrieving panelist ${panelistId} data:`, error);
            return null;
          })
      );

      const results = await Promise.all(downloadPromises);

      const processingPromises = results.map(result => {
        if (result) {
          return this.processPanelistData(result.panelistData, result.panelistId);
        }
        return null;
      });

      await Promise.all(processingPromises);
      this.loading = false;
    } else {
      console.error('No panelists selected for extraction.');
    }
  }

  async processPanelistData(panelistData: any[], panelistId: string): Promise<void> {
    const timelinePromises: Array<Promise<ClassificationData | null>> = [];

    panelistData.forEach(panelist => {
      if (panelist.timeline) {
        panelist.timeline.forEach(t => {
          if (t.subclass === 'characterisation') {
            const promise = this.volunteerService.getFicheCaracterisation(t.id, this.hub).toPromise()
              .then(characterisationData => ({ characterisationData, createdOn: t.created_on } as ClassificationData))
              .catch(error => {
                console.error(`Error retrieving characterisation data for ${t.id}:`, error);
                return null;
              });
            timelinePromises.push(promise);
          }
        });
      }
    });

    const characterisationResults = await Promise.all(timelinePromises);

    const hairData = characterisationResults.filter(result => result.characterisationData.metier && result.characterisationData.metier.id === 'hair');
    const skinData = characterisationResults.filter(result => result.characterisationData.metier && result.characterisationData.metier.id === 'skin');

    await this.createExcel(panelistData, hairData, skinData, panelistId);
  }

  async createExcel(panelistData: any[], hairData: ClassificationData[], skinData: ClassificationData[], panelistId: string): Promise<void> {
    const workbook = new Excel.Workbook();
    const timelineSheet = workbook.addWorksheet('Timeline');

    timelineSheet.columns = [
      { header: 'Date', key: 'date', width: 20 },
      { header: 'Actiview Number', key: 'activiewNumber', width: 30 },
      { header: 'Synergy Number', key: 'synergyNumber', width: 30 },
      { header: 'Type', key: 'type', width: 20 },
    ];

    panelistData.forEach(panelist => {
      if (panelist.timeline) {
        panelist.timeline.forEach(t => {
          timelineSheet.addRow({
            date: new Date(t.created_on).toLocaleDateString(),
            activiewNumber: t.activityNumber || '',
            synergyNumber: t.requestNumber || '',
            type: t.subclass || ''
          });
        });
      }
    });

    if (hairData.length > 0) {
      const caracHairSheet = workbook.addWorksheet('caracHair');
      this.addClassificationDataToSheet(caracHairSheet, hairData);
    }

    if (skinData.length > 0) {
      const caracSkinSheet = workbook.addWorksheet('caracSkin');
      this.addClassificationDataToSheet(caracSkinSheet, skinData);
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `panelist_${panelistId}.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  addClassificationDataToSheet(sheet: Excel.Worksheet, classificationData: ClassificationData[]): void {
    const headers = [{ header: 'Attribute Name', key: 'attributeName', width: 50 }];
    const dates = [];

    classificationData.forEach(data => {
      const date = new Date(data.createdOn).toLocaleDateString();
      if (!dates.includes(date)) {
        dates.push(date);
        headers.push({ header: date, key: date, width: 30 });
      }
    });

    sheet.columns = headers;

    const attributeRows: { [key: string]: any } = {};
    classificationData.forEach(data => {
      data.characterisationData.AttributeData.forEach(attribute => {
        if (!attributeRows[attribute.AttributeName]) {
          attributeRows[attribute.AttributeName] = { attributeName: attribute.AttributeName };
        }
        if (attribute.AttributeType == 3 && attribute.Value) { // Handle date type attributes
          const date = new Date(data.createdOn).toLocaleDateString();
          attributeRows[attribute.AttributeName][date] = this.dateService.formatDate(attribute.Value);
        } else if (attribute.AttributeType == 5 && attribute.Value) { // Handle multiple values
          const codes = attribute.Value.split(',');
          const detailsText = attribute.AttributeDetails
            .filter(detail => codes.includes(detail.Code))
            .map(detail => detail.Text)
            .join(', ');
          const date = new Date(data.createdOn).toLocaleDateString();
          attributeRows[attribute.AttributeName][date] = detailsText;
        } else { // Handle single value attributes
          const detail = attribute.AttributeDetails.find(d => d.Code === attribute.Value);
          if (detail) {
            const date = new Date(data.createdOn).toLocaleDateString();
            attributeRows[attribute.AttributeName][date] = detail.Text;
          }
        }
      });
    });

    Object.values(attributeRows).forEach(row => {
      sheet.addRow(row);
    });
  }
}
