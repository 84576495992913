import {MultiCampaignsService} from './../../../multi-campaigns.service';
import {DNATranslateService} from './../../../../shared/services/translate.service';
import {ChartService} from './../../../../campaigns/detail/edit-campaign/analyse/reports/chart-module/services/chart-service.service';
import {chartsConfigs} from './../../../../campaigns/detail/edit-campaign/analyse/reports/chart-module/configs/index';

import {Graph, Language, Metier, Translatable, Image, OnePager, Campaign} from './../../../../types';
import {AnalyseMultiService} from './../analyse-multi.services';
import {Router, ActivatedRoute} from '@angular/router';

import * as _ from 'lodash';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {map, mergeMap, take, tap, finalize, takeUntil, catchError, flatMap, filter, switchMap} from 'rxjs/operators';
import {empty as observableEmpty, forkJoin, Observable, Subject, of, zip} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {UtilService} from './../../../../shared/services/util.service';
import {ErrorManagerService} from './../../../../shared/services/errorManager.service';
import {OnePagerService} from './../../../../shared/services/one-pager.service';
import {CampaignService} from './../../../../campaigns/campaigns.service';
import { UserService } from './../../../../shared/services/user.service';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-analyse-multi-report',
  templateUrl: './report-multi.component.html',
  styleUrls: ['./report-multi.component.less']
})

export class AnalyseMultiReportComponent implements OnInit, OnDestroy, AfterViewInit {

  campaignMultiId: string;
  campaignMultiName: string;

  public filters: any;
  public filterPanelists: any[] = [];
  public filtersLoading = true;
  public report: Array<any> = new Array(2);
  public reportError: any;
  public showSpinner = false;
  public filterCollapsed = true;
  public menuCollapsed = true;
  public lang: Language;
  onePager: OnePager;
  campaigns: Campaign[];
  images: Image[] = [];
  initTime = performance.now();

  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedGraphs = [];

  private trackUpdate = '_first_render';
  private _disabledVolunteer;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private analyseMultiService: AnalyseMultiService,
    private multiCampaignsService: MultiCampaignsService,
    private translate: TranslateService,
    private translateService: DNATranslateService,
    private aiService: ApplicationInsightsService,
    private chartService: ChartService,
    private utilService: UtilService,
    private errorManagerService: ErrorManagerService,
    private onePagerService: OnePagerService,
    private campaignService: CampaignService,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.route.paramMap.pipe(
      map(param => param.get('idCampaignMulti')),
      tap(id => this.campaignMultiId = id),
      switchMap(() => this.multiCampaignsService.getMultiStudiesCampaigns(this.campaignMultiId)),
      tap((res) => {
        this._disabledVolunteer = res;
      }),
      tap(() => this.campaignMultiName = this.multiCampaignsService.getCampaignMultiFromLocalStorage(this.campaignMultiId)),
      mergeMap(() => this.loadReport(this.campaignMultiId)),
      flatMap(() => this.multiCampaignsService.getCampaignMultiOnePager(this.campaignMultiId)),
      flatMap((result: {id: string}) => this.onePagerService.getAndBuildOnePagerMulti(result.id, this.campaignMultiId)),
      tap(onePager => this.onePager = onePager),
      tap(() => this.showSpinner = false),
      mergeMap((onePager: OnePager) => of(this.campaignService.fillImagesTab(onePager))),
      tap(() => {
        this.lang = this.translateService.getLanguage();
        this.translateService.onLangChange().subscribe(({lang}: any) => {
          this.lang = lang;
        });
      })
    ).subscribe((images: Image[]) => {
      this.images = _.compact(images);
    });
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Multi Campaign Analyse Report', '', performance.now() - this.initTime, templateUrl);
  }

  ngOnDestroy() {
    this.utilService.isAnalyseGraphEdit.emit(false);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  editChart() {
    this.router.navigate(['multi-campaigns', this.campaignMultiId, 'analyse', 'editCharts']);
  }

  /**
   * loadReport
   * Main method in charge of getting data and parameters for the current campaign.
   * It gathers those informations into proper properties for each graph of the report.
   * @param campaignId  : string
   * @param metier      : string
   */
  private loadReport = (campaignId: string, metier?: Metier): Observable<any> => {
    return this.getReport(campaignId).pipe(
      tap((resp: any) => this.filters = _.cloneDeep(resp.filters)),
      tap((resp: any) => {
        this.campaigns = resp.campaigns;
        this.report = resp.charts
          .map((chart: any, index: number) => {
            chart.identifier = index + 1;
            chart.toCapture = _.has(chart, 'toCapture') ? chart.toCapture : false;
            return chart;
          });
      }),
      mergeMap((resp: any) => this.getChartsData(resp.charts, campaignId, resp.filters, this.campaigns, metier)),
      tap(() => this.filters.panelists = this.filterPanelists)
    );
  };

  /**
   * getReport
   * Method to get report (all chart to come up with) for a given campaign and workflow
   * @param campaignId : string
   */
  private getReport = (campaignId: string): Observable<any> => {
    // return of(this.campaignMulti.graphs);
    return this.analyseMultiService.getChartsFromCampaignMulti(campaignId).pipe(take(1));
  };


  /**
   * getChartsData
   * Method to get data and parameters (for all chart to come up with) for a given campaign and workflow
   * @param chartArray : Array<any>
   * @param campaignId : string
   * @param filters
   * @param campaigns
   * @param metier
   */
  private getChartsData = (chartArray: Graph[], campaignId: string, filters: any, campaigns: Campaign[], metier?: Metier): Observable<any> => {
    return forkJoin(
      chartArray.map((chart: any) => {
        return this.getRawData(campaignId, filters, campaigns, chart).pipe(
          tap(chart => this.filterPanelists = _.uniqBy(this.filterPanelists.concat(_.get(chart, 'filters.panelists', []).map(p => this.filters.panelists.find(fp => fp.id === p.id))), 'id')),
          tap(chart => this.getParameters(chart, metier)),
          tap(chart => {
            const element = this.report.find(x => x.identifier == chart.identifier);
            element.chart = ChartService.instanciateChart(element.parameters.configs, element.settings, {
              data: chart.data,
              pDescriptors: chart.pDescriptors,
              routines: chart.routines
            }, element.parameters.type, chart.filters, this.lang, this.translate);
            return element;
          }),
          takeUntil(this.destroy$),
          catchError(err => {
            // bug 21882 : si un erreur lors de la creation du graphe, on continue le traitement des autres graphes
            console.log('Erreur getChartData : ', err);
            this.errorManagerService.displayMessage('Une erreur s\'est produite avec le Graphique #' + chart.identifier, 'danger');
            return of({});
          }),
          finalize(() => this.filtersLoading = false)
        );
      })
    );
  };

  private formatChart(chart) {
    const data = _.cloneDeep(chart);
    delete data.chart;
    return data;
  }

  /**
   * getRawData
   * Method to get data for a given chart
   * @param campaignId  : string
   * @param filters     : any
   * @param campaigns
   * @param chart       : any
   */
  private getRawData = (campaignId: string, filters: any, campaigns: Campaign[], chart: any): Observable<any> => {

    if (this._disabledVolunteer.studies) {
      this._disabledVolunteer.studies.forEach((campaign) => {
        for (const [index, campaignLocal] of campaigns.entries()) {
          if (campaign.id === campaignLocal.id) {
            campaigns[index].evaluations.forEach((evaluation, number) => {
              if (campaign.users) {
                campaign.users.forEach((user) => {
                  if (evaluation.volunteer.name === user.name && !user.isActive) {
                    campaigns[index].evaluations.splice(number, 1);
                  }
                });
              }
            });
          }
        }
      });
    }

    return this.analyseMultiService.getChartByIdCampaignMulti(campaignId, this.formatChart(chart), filters, campaigns).pipe(
      take(1),
      takeUntil(this.destroy$),
      map((res: any) => {
        chart.data = res.data;
        chart.filters = res.filters;
        chart.pDescriptors = res.categories;
        return chart;
      })
    );
  };


  /**
   * getParameters
   * Method to get configurations parameters for a given chart
   * @param chart : any
   * @param metier : Metier
   */
  private getParameters = (chart: any, metier?: Metier) => {
    const chartType = chart.type.id;
    chart.parameters = chartsConfigs[_.get(metier, 'id', 'hair')][chartType];
    Object.assign(chart.parameters.configs.title, {translations: chart.name});
    Object.assign(chart.parameters.configs.subtitle, {translations: chart.type.translations});
    return chart;
  };

  /**
   * _onFiltersUpdate
   * Method called when filters are updated.
   * Handling this task when EventEmitter is fired from the children.
   * This method is in charge to relaunch report getting workflow to get
   * updated data filtered by user's entries.
   * @param filters : Updated filters
   */
  public onFiltersUpdate = async (filters: any): Promise<any> => {
    this.showSpinner = true;
    this.getChartsData(this.report, this.campaignMultiId, this.filters, this.campaigns).pipe(
      tap(() => this.showSpinner = false)
    ).subscribe(
      () => {
        this.trackUpdate = '_' + Date.now();
      }
    );
  };

  /**
   * _onCollapse
   * Method to collapse/expand filters panel
   * @param status {boolean}
   */
  public _onCollapse = (status: boolean) => this.filterCollapsed = status;
  public _onCollapseMenu = (status: boolean) => this.menuCollapsed = status;

  /**
   * trackByFunction
   * Method to track elements loop by id or index (fallback option)
   * @param index {number}
   * @param elt {any}
   */
  public trackByFunction = (index: number, elt: any) => {
    if (!elt) {
      return null;
    }
    return elt.id ? elt.id + this.trackUpdate : index + this.trackUpdate;
  };

  public _onExporting = (): void => {
    this.showSpinner = true;
  };
  public _onExcelDownload = (obj: any) => {
    this.showSpinner = true;
    try {
      this.chartService.exportToExcel(obj.el, obj.name);
    } catch (e) {
    } finally {
      this.showSpinner = false;
    }
  };

  onImageLoad = (file, name, id, identifier) => {
    this.showSpinner = true;
    this.utilService.uploadFile(file).subscribe({
      next: (data) => {
        this.onImageLoaded(data, name, id, identifier);
      }
    });
  };

  onImageLoaded(tab, name: Translatable, id: string, identifier: number) {
    const file = tab[0];
    const array = tab[1];
    this.showSpinner = true;
    this.utilService.uploadMediaFromComputer(file, array[1], array[0]).pipe(
      catchError(this.catchError),
      map((url: string) => this.utilService.setImage(url, name, `${array[0]},${array[1]}`, id, identifier)),
      tap((image: Image) => this.onePagerService.addImage(this.onePager.id, image)),
      map((image: Image) => this.onePagerService.addCaptureInOnePagerImages(this.onePager, image)),
      mergeMap((onePager: OnePager) => this.onePagerService.updateOnePager(this.onePager.id, _.pick(this.onePager, ['images']))),
      finalize(() => {
        this.showSpinner = false;
      }),
      takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.errorManagerService.displayMessage('VIZUALIZATIONS.BOARD.IMAGE_SUCCESSFULLY_UPLOADED');
        },
        err => this.errorManagerService.catchError(err)
      );
  }

  catchError = (error) => {
    console.log(error);
    this.showSpinner = false;
    this.errorManagerService.catchError(error);
    return observableEmpty();
  };

  onChangeGraphToCapture(graph) {
    graph.toCapture = !_.get(graph, 'toCapture', false);
  }

  takeCaptures() {
    this.showSpinner = true;
    if (this.report.some(chart => chart.toCapture)) {
      ChartService.onTakeCaptures();
    } else {
      this.errorManagerService.displayMessage('ERROR_NO_GRAPHS_SELECTED', 'danger');
      this.showSpinner = false;
    }
  }

  /**
   * 20765 Take capture and save it in onePager images for all graphs selected
   * @param multiplesCapturesElements
   */
  onMultipleCaptures(multiplesCapturesElements: [Observable<any>, any]) {
    this.selectedGraphs.push(multiplesCapturesElements);
    if (this.selectedGraphs.length === this.report.filter(graph => graph.toCapture).length) {
      const obs = [];
      const tabNames = this.selectedGraphs.map(mce => {
        return {
          name: _.get(mce, '[1].name', _.get(mce, '[1].type.translations', '')),
          identifier: _.get(mce, '[1].identifier', ''),
          id: _.get(mce, '[1].id', '')
        };
      });
      const tabCaptures = this.selectedGraphs.map(mce => _.get(mce, 0, of('')));
      tabCaptures.forEach((capture, index) => {
        let array = [];
        const cpt = capture.pipe(
          filter(capture => capture !== ''),
          mergeMap(capture => {
            return this.utilService.uploadFile(capture);
          }),
          mergeMap(data => {
            const file = data[0];
            array = data[1];
            return this.utilService.uploadMediaFromComputer(file, array[1], array[0]);
          }),
          catchError(this.catchError),
          map((url: string) => this.utilService.setImage(url, tabNames[index].name, `${array[0]},${array[1]}`, tabNames[index].id, tabNames[index].identifier)),
          finalize(() => this.showSpinner = false)
        );
        obs.push(cpt);
      });
      zip(...obs).pipe(
        map((images: Image[]) => this.addIndexes(images)),
        mergeMap((images: Image[]) => this.addAllImagesInOnePager(images)),
        tap((onePager: OnePager) => this.onePagerService.updateOnePager(onePager.id, _.pick(onePager, ['images']))),
        catchError(this.catchError)
      ).subscribe(
        (res) => {
          this.showSpinner = false;
          this.errorManagerService.displayMessage('VIZUALIZATIONS.BOARD.IMAGES_SUCCESSFULLY_UPLOADED');
        },
        err => this.errorManagerService.catchError(err)
      );
    }
  }

  /**
   * 20765 Add each image of graph captured in onePager images and save it
   * @param images
   * @returns
   */
  addAllImagesInOnePager(images: Image[]): Observable<OnePager> {
    images.forEach(image => {
      this.images.push(image);
      this.onePagerService.addImage(this.onePager.id, image);
      this.onePager = this.onePagerService.addCaptureInOnePagerImages(this.onePager, image);
    });
    return this.onePagerService.updateOnePager(this.onePager.id, _.pick(this.onePager, ['images']));
  }

  /**
   * 21023 Add index to capture's name if multiple graphs with same name(type)
   * @param images
   * @returns
   */
  addIndexes(images: Image[] = []) {
    const originalImages = _.cloneDeep(images);
    return images.map(image => {
      if (originalImages.filter(i => i.name === image.name).length > 1) {
        image.name = image.name.concat(` ${image.indexImage}`);
      }
      return image;
    });
  }

  saveChartOptions(event) {
    const language = _.get(this.userService.getUser(), 'language', 'english');
    const idGraph = this.report.find(r => r.identifier === event.identifier).id;
    this.multiCampaignsService.putCampaignMultiGraphOptions(this.campaignMultiId, {
      idGraph: idGraph,
      chartTitle: event.title,
      language: language,
      comment: event.comment,
      parameters: event.parameters
    }).pipe(
      catchError(err => {
        console.log(err);
        return undefined;
      })
    ).subscribe();
  }


}
