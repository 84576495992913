import {UtilService} from '../../../shared/services/util.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MultiCampaignsService} from '../../multi-campaigns.service';
import {catchError, tap, finalize, take, switchMap, map, takeUntil} from 'rxjs/operators';
import {EMPTY, from, iif, Subject, throwError as observableThrowError} from 'rxjs';
import {
  CampaignMultiVolunteer,
  CampaignsMulti,
  CampaignsMultiPutUsers,
  CampaignsMultiUsers,
  CampaignsUsers,
  Volunteers,
  Translatable
} from '../../../types';
import {ErrorManagerService} from '../../../shared/services/errorManager.service';
import * as _ from 'lodash';
import {CampaignService} from '../../../campaigns/campaigns.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersConfigComponent} from './users-config/users-config.component';
import {AbandonCaseEnum} from '../../../enums/campaign/abandonCase.enum';
import {DNATranslateService} from '../../../shared/services/translate.service';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-users',
  templateUrl: './users-multi.component.html',
  styleUrls: ['./users-multi.component.less']
})
export class UsersMultiComponent implements OnInit, AfterViewInit {

  usersOk = false;
  showSpinner = false;
  campaignsMultiUsers: CampaignsUsers[];
  duplicateVolunteers: { [name: string]: [] };
  initTime = performance.now();
  private _campaignMultiId = '';

  constructor(
    private route: ActivatedRoute,
    private campaignMultiService: MultiCampaignsService,
    private errorManager: ErrorManagerService,
    private campaignService: CampaignService,
    private aiService: ApplicationInsightsService,
    public utilService: UtilService,
    private dnaTranslateService: DNATranslateService,
    private _customModalService: UtilService
  ) {
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Multi Campaigns Details Users', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.showSpinner = true;
    this._campaignMultiId = this.route.parent.snapshot.paramMap.get('idCampaignMulti');
    this.campaignMultiService.getCampaignMultiUsers(this._campaignMultiId).pipe(
      catchError(err => {
        this.usersOk = false;
        this.showSpinner = true;
        return this.onErrorRequest(err);
      }),
      tap((campaignsMultiUsers: CampaignsMultiUsers) => this.campaignsMultiUsers = campaignsMultiUsers.studies),
      tap((campaignsMultiUsers: CampaignsMultiUsers) => this.initCampaigns(campaignsMultiUsers.studies)),
      tap((campaignsMultiUsers: CampaignsMultiUsers) =>
        this.campaignMultiService.hasNameChanged.next(campaignsMultiUsers.name)),
      tap(() => this.duplicateVolunteers = this.getDuplicateVolunteers(this.campaignsMultiUsers)),
      tap(() => this.usersOk = true),
      finalize(() => this.showSpinner = false),
      take(1)
    ).subscribe();
  }

  addNames(campaignsMultiUsers: CampaignsUsers[]): CampaignsUsers[] {
    campaignsMultiUsers = campaignsMultiUsers.map(campaign => {
      campaign.accountables = _.get(campaign, 'accountables', []).map(a => {
        a.name = _.startCase(this.campaignService.decodeUserName(a.name));
        return a;
      });
      return campaign;
    });
    return campaignsMultiUsers;
  }

  getDuplicateVolunteers(campaignsMultiUsers: CampaignsUsers[]): { [name: string]: [] } {
    let volunteers = [];
    campaignsMultiUsers.filter(cmu => cmu.isVolunteer).forEach(cmu => volunteers = volunteers.concat(cmu.volunteers));
    return _.groupBy(volunteers, 'name');
  }

  initCampaigns(campaigns: CampaignsUsers[]) {
    campaigns.filter(cmu => cmu.isVolunteer).forEach(cmu => {
      cmu.volunteers = _.sortBy(cmu.volunteers.filter(v => _.get(v, 'progress', 0) > 0), ['name']);
    });
    campaigns = this.addNames(campaigns);
    return campaigns;
  }

  onErrorRequest = (err: Response) => {
    this.showSpinner = false;
    this.errorManager.catchError(err);
    return observableThrowError(err);
  };

  // on prend de préférence le nom du questionnaire dans la langue utilisée ; s'il n'existe pas,
  // on prend n'importe quelle langue non vide
  getWorkflowName(workflowNames: Translatable) {
    if(workflowNames[this.dnaTranslateService.getLanguage()])
    {
     return workflowNames[this.dnaTranslateService.getLanguage()];
    } else
    {
      let nonEmptyTranslations = Object.entries(workflowNames).map(c => c[1]).filter(word => word.length > 0);
      return nonEmptyTranslations[0];
    }
  }

  changeUserActive() {
    const _destroyed: Subject<void> = new Subject<void>();
    this.campaignMultiService.getMultiStudiesCampaigns(this._campaignMultiId).pipe(
      takeUntil(_destroyed),
      tap((campaignsMulti: CampaignsMulti) => {
        return campaignsMulti.studies.forEach((campaign: { id: string, name: string, users: [Volunteers] }) => {
          this.campaignsMultiUsers.forEach((campaignsUsers: CampaignsUsers, index) => {
            if (campaignsUsers.campaignId === campaign.id && campaignsUsers.isVolunteer) {
              campaignsUsers.volunteers.forEach((volunteers: Volunteers, volunteerIndex) => {
                if (campaign.users) {
                  campaign.users.forEach((volunteerFromMulti: Volunteers) => {
                    if (volunteerFromMulti.name === volunteers.name) {
                      this.campaignsMultiUsers[index].volunteers[volunteerIndex].isActive = volunteerFromMulti.isActive;
                    }
                  });
                }
              });
            }
          });
        });
      }),
      switchMap(() => from(this._customModalService.openCustomModal({
        title: 'VOLUNTEERS_DISABLED',
        message: '',
        bodyComponent: UsersConfigComponent,
        bodyComponentInputs: {campaignsMultiUsers: this.campaignsMultiUsers},
      }).result)),
      switchMap((result: string) => {
        if (result === 'validation') {
          const campaignsUserToUpdate: CampaignsMultiPutUsers = {studies: []};
          this.campaignsMultiUsers.filter(campaign => campaign.isVolunteer).forEach((campaign) => {
            const users: CampaignMultiVolunteer[] = [];
            campaign.volunteers.forEach((volunteer: Volunteers) => {
              users.push({name: volunteer.name, isActive: volunteer.isActive});
            });
            campaignsUserToUpdate.studies.push({
              campaignId: campaign.campaignId,
              users,
            });
          });
          return this.campaignMultiService.setUsersMulti(this._campaignMultiId, campaignsUserToUpdate);
        } else {
          return EMPTY;
        }
      })
    ).subscribe(() => {
        _destroyed.next();
      },
      () => {
        _destroyed.next();
      });
  }
}


// this._customModalService.openCustomModal({
//   title: 'Désactivation d\'utilisateurs',
//   message: '',
//   bodyComponent: UsersConfigComponent,
//   bodyComponentInputs: {campaignsMultiUsers: this.campaignsMultiUsers},
// }).then((result) => {
//   const campaignsUserToUpdate: CampaignsMultiPutUsers = {campaigns: []};
//   this.campaignsMultiUsers.filter(campaign => campaign.isVolunteer).forEach((campaign) => {
//     const users: CampaignMultiVolunteer[] = [];
//     campaign.volunteers.forEach((volunteer: Volunteers) => {
//       users.push({name: volunteer.name, isActive: volunteer.isActive});
//     });
//     campaignsUserToUpdate.campaigns.push({
//       campaignId: campaign.campaignId,
//       users,
//     });
//   });
//   this.campaignMultiService.setUsersMulti(this._campaignMultiId, campaignsUserToUpdate).subscribe();
// });
