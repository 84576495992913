import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';
import { Subscription, of } from 'rxjs';

import {
  Block,
  DNAComponent,
  JsonPatch,
  States
} from '../../../../types';
import {
  BlockService
} from '../../../blocks.service';
import {
  DNATranslateService
} from '../../../../shared/services/translate.service';
import {
  ErrorManagerService
} from '../../../../shared/services/errorManager.service';
import { tap, catchError } from 'rxjs/operators';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-component-configuration',
  templateUrl: './component-configuration.component.html',
  styleUrls: ['./component-configuration.component.less']
})
export class ComponentConfigurationComponent implements OnInit, AfterViewInit {

  block: Block;
  blockUntouched: Block;
  component: DNAComponent;
  componentUntouched: DNAComponent;
  currentLanguage: string;
  fieldsOfComponents: any[] = [];
  hasError: boolean;
  inactive: boolean;
  indexComponent: number;
  languages: string[] = [];
  submitted: boolean;
  subscription: Subscription;
  showSpinner: boolean = false;
  initTime = performance.now();

  constructor(
    private blockService: BlockService,
    private DNATranslate: DNATranslateService,
    private errorManager: ErrorManagerService,
    private aiService: ApplicationInsightsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.blockService.component) {
      this.block = this.blockService.block;
      this.blockUntouched = _.cloneDeep(this.block);
      this.component = _.cloneDeep(this.blockService.component);
      this.componentUntouched = _.cloneDeep(this.component);
      this.currentLanguage = this.DNATranslate.getLanguage();
      this.fieldsOfComponents.push(this.DNATranslate.getTranslatablesFieldsOfComponent(this.component.type));
      this.inactive = this.block.state === States.Published;
      this.indexComponent = this.blockService.indexComponent;

      this.DNATranslate.getLanguages().then(
        request => this.languages = request
      );

      this.subscription = this.DNATranslate.onLangChange().subscribe((translation: any) => {
        this.currentLanguage = translation.lang;
      });
    } else {
      this.subscription = this.route.parent.paramMap.subscribe((params: ParamMap) => this.router.navigate(['blocks', params.get("idBlock"), 'addComponents']))
    }
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Block Component Configuration', '', performance.now() - this.initTime, templateUrl);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  cancel() {
    if (!this.inactive) {
      this.blockService.block = _.cloneDeep(this.blockUntouched);
    }
    this.router.navigate(['blocks', this.block.id]);
  }

  validate() {
    this.submitted = true;
    if (!this.hasError) {
      this.showSpinner = true;
      if (_.isNumber(this.indexComponent)) {
        this.blockService.updateComponentFromBlock(this.updateComponent(this.component, this.componentUntouched));
      } else {
          this.blockService.addComponentToBlock({..._.pick(this.component, ['args', 'type']), index: this.block.components.length});
      }
      this.blockService.isBlockChanged = true;
      this.router.navigate(['blocks', this.block.id]);
    } else {
      this.errorManager.displayMessage("ON_ERROR_FORM", "danger");
    }
  }

  updateComponent(component: DNAComponent, initialComponent: DNAComponent): DNAComponent {
    let updatedComponent: DNAComponent = {...initialComponent};
    if (!_.isEqual(component.args, initialComponent.args)) {
      updatedComponent = {
        ...initialComponent,
        args: component.args
      };
    }
    if (!_.isEqual(component.mandatory, initialComponent.mandatory)) {
      updatedComponent.mandatory = component.mandatory;
    }
    return updatedComponent;
  }

}
