
import { throwError as observableThrowError, Observable } from 'rxjs';

import { tap, catchError, mergeMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';
import { VolunteerService } from '../volunteers.service';
import { ApplicationInsightsService } from '../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-volunteer-detail',
  templateUrl: 'volunteerDetail.component.html'
})

export class VolunteerDetailComponent implements OnInit, AfterViewInit {
  showSpinner: boolean;
  initTime = performance.now();

  constructor(
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private volunteerService: VolunteerService,
    private router: Router
  ) {}

  ngOnInit() {
    if (!this.volunteerService.getPanelistIsLoaded()) {
      this.showSpinner = true;
      let refPanelist = 'arcs'
      this.route.params.pipe(
        tap(p =>  refPanelist = /^[0-9]+$/.test(p.idVolunteer) ? 'arcs' : 'horsarcs'),
        mergeMap(p => this.volunteerService.getPanelistById(p.idVolunteer, p.hub, refPanelist)),
        catchError(err => {
          this.router.navigate(['volunteers']);
          return observableThrowError(err);
        }),
        tap(volunteer => this.volunteerService.setPanelist(volunteer)),
        tap(() => this.showSpinner = false)
      ).subscribe()
    } else {
      this.volunteerService.setPanelistIsLoaded(false)
    }
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Volunteer Details', '', performance.now() - this.initTime, templateUrl);
  }
}
