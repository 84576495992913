import { DNATranslateService } from './../../../../shared/services/translate.service';
import { ErrorManagerService } from './../../../../shared/services/errorManager.service';
import { UtilService } from './../../../../shared/services/util.service';
import { MultiCampaignsService } from './../../../multi-campaigns.service';
import { CampaignMultiDescriptor, DescriptorGroup, ModalContent, Descriptor, Graph } from './../../../../types';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap, take, tap, catchError, finalize } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import * as _ from 'lodash';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';


@Component({
  selector: 'dna-descriptors-multi',
  templateUrl: './descriptors-multi.component.html',
  styleUrls: ['./descriptors-multi.component.less']
})
export class DescriptorMultiComponent implements OnInit, AfterViewInit {
  multiCampaign: CampaignMultiDescriptor;
  showSpinner: boolean;
  graphs: Graph[] = [];
  sorted: boolean = false;
  reverse: boolean = true;
  initTime = performance.now();

  constructor(
    private router: Router,
    private multiCampaignsService: MultiCampaignsService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private errorManagerService: ErrorManagerService,
    private translateService: DNATranslateService
  ) { }

  ngOnInit() {
    this.multiCampaign = this.multiCampaignsService.getLocalCampaignMultiDescriptor();
    this.graphs =  _.cloneDeep(_.get(this.multiCampaign, 'graphs', []));
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Multi Campaigns Details Descriptors List', '', performance.now() - this.initTime, templateUrl);
  }

  addGroupDescriptor() {
    this.router.navigate(['multi-campaigns', this.multiCampaign.id, 'descriptors', 'new', 'detail']);
  }

  openGroup(descriptorGroup: DescriptorGroup) {
    this.router.navigate(['multi-campaigns', this.multiCampaign.id, 'descriptors', descriptorGroup.id, 'detail']);
  }

  removeDescriptorsGroup(descriptorsGroup: DescriptorGroup, index: number) {
    this.utilService.translateMessageModal('CONFIRM_DELETE', descriptorsGroup.name.english, 'DESCRIPTOR').pipe(
      mergeMap((modalContent: ModalContent) => this.utilService.openModalConfirm(modalContent)),
      mergeMap(() => this.deleteDescriptorsGroup(index)),
      tap(() => this.multiCampaignsService.setLocalCampaignMultiDescriptor(this.multiCampaign)),
      take(1),
    ).subscribe();
  }

  getName(descriptors: Descriptor[], key: string): string {
    const lang = this.translateService.getLanguage();
    return descriptors.reduce((s, d, i) => {
      if (i > 0) {
        s = key === 'name' ? s + '<br/>' : s + ' | ';
      }
      s += key === 'name' ? this.translateService.getTranslation(d[key], lang).concat(_.get(d, 'blockSuffix', '')) : this.translateService.getTranslation(d[key], lang);
      return s;
    }, "")
  }

  autoCreateGroupDescriptor() {
    // const modalOption: NgbModalOptions = {
    //   backdrop: "static",
    //   keyboard: false,
    //   size: "lg"
    // };
    // const modal = this.modalService.open(ModalAutoCompleteDescriptor, modalOption);
    // modal.componentInstance.campaignMultiDescriptor = this.multiCampaign;
    // modal.result.then((data) => {
    //   const selectedWorkflow: Pick<Workflow, "id" | "name"> & { campaignName: string, campaignId: string, idMulti: string } = data.selectedWorkflow
    // })

    const pertinence: number = 80;
    let wks = [];
    this.showSpinner = true;
    this.multiCampaign.studies.map(c => c.workflows.map(wk => wks.push({id: wk.id, campaignId: c.id})));
    const nbVisits = this.multiCampaign.studies.map(c => c.nbVisits);
    wks.forEach(wk => this.multiCampaignsService.autocompleteDescriptorsGroups(this.multiCampaign, wk, pertinence, nbVisits));

      this.updateDescriptors().pipe(
        tap(() => this.multiCampaignsService.setLocalCampaignMultiDescriptor(this.multiCampaign)),
        take(1)
      ).subscribe();
  }

  updateDescriptors(): Observable<{descriptorsGroups: DescriptorGroup[], graphs: Graph[]}> {
    return this.multiCampaignsService.putCampaignMultiDescriptor(this.multiCampaign).pipe(
      tap(() => this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE')),
      tap(() => this.showSpinner = false),
      catchError(err => {
        this.showSpinner = false;
        this.errorManagerService.displayMessage('UNKNOW_ERROR', 'danger');
        return throwError(err);
      })
    )
  }

  deleteDescriptorsGroup(index: number): Observable<{descriptorsGroups: DescriptorGroup[], graphs: Graph[]}> {
    const deletedDescr = this.multiCampaign.descriptorsGroups.splice(index, 1)[0];
    _.get(this.multiCampaign, "graphs", []).forEach((graph: Graph, indexGraph) => {
      _.get(graph, 'descriptorsGroupIds', []).forEach((descriptorsGroup: DescriptorGroup, indexDg) => {
        let deleteGroup = false;
        if (descriptorsGroup.isGroup) {
          descriptorsGroup.descriptorsGroupIds = descriptorsGroup.descriptorsGroupIds.filter(d => d.id !== deletedDescr.id);
          if (_.isEmpty(descriptorsGroup.descriptorsGroupIds)) {
            deleteGroup = true;
          }
        }
        if (descriptorsGroup.id === deletedDescr.id || deleteGroup) {
          graph.descriptorsGroupIds.splice(indexDg, 1);
        }
      });
      if (_.isEmpty(_.get(graph, 'descriptorsGroupIds', []))) {
        this.multiCampaign.graphs.splice(indexGraph, 1);
      }
    });
    return this.updateDescriptors().pipe(
      tap((obj) => this.graphs = _.cloneDeep(obj.graphs)),
      catchError(err => {
        this.multiCampaign.descriptorsGroups.splice(index, 0, deletedDescr);
        this.multiCampaign.graphs = _.cloneDeep(this.graphs)
        return throwError(err);
      })
    )
  }

  sort() {
    this.sorted = true;
    this.reverse = !this.reverse;
    const language = this.translateService.getLanguage();
    this.multiCampaign.descriptorsGroups = this.utilService.sortListByType(this.multiCampaign.descriptorsGroups, 'name', this.reverse, true, language);
  }

  deletaAllDescriptors() {
    if (!_.isEmpty(this.multiCampaign.descriptorsGroups)) {
      this.showSpinner = true;
      const oldDescriptorsGroup = _.cloneDeep(this.multiCampaign.descriptorsGroups);
      this.multiCampaign.descriptorsGroups = [];
      if (!_.isEmpty(this.multiCampaign.graphs)) {
        this.multiCampaign.graphs.forEach((graph: Graph) => {
          graph.descriptorsGroupIds = [];
        });
      }
      this.updateDescriptors().pipe(
        catchError(err => {
          this.multiCampaign.descriptorsGroups = _.cloneDeep(oldDescriptorsGroup);
          this.multiCampaign.graphs = _.cloneDeep(this.graphs);
          return throwError(err);
        }),
        tap((obj) => this.graphs = _.cloneDeep(obj.graphs)),
        tap((obj) => this.multiCampaign.graphs = _.cloneDeep(obj.graphs)),
        tap(() => this.multiCampaignsService.setLocalCampaignMultiDescriptor(this.multiCampaign)),
        finalize(() => this.showSpinner = false)
      ).subscribe();
    }
  }
}
