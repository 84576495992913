import { AfterViewInit, Component, OnInit } from '@angular/core';
import {AnalyseTypes, Type, CampaignsMultiInfoGen} from '../../../types';
import {MultiCampaignsService} from '../../../multi-campaigns/multi-campaigns.service';
import {ActivatedRoute, Router} from '@angular/router';
import {throwError as observableThrowError, Subject, Observable} from 'rxjs';
import {tap, catchError, finalize, takeUntil} from 'rxjs/operators';
import {UtilService} from '../../../shared/services/util.service';
import * as _ from 'lodash';
import {ReferenceTypeService} from '../../../shared/services/reference-type.service';
import {NgForm} from '@angular/forms';
import {ErrorManagerService} from '../../../shared/services/errorManager.service';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-general-information-multi',
  templateUrl: './general-information-multi.component.html',
  styleUrls: ['./general-information-multi.component.less']
})
export class GeneralInformationMultiComponent implements OnInit, AfterViewInit {

  campaignMulti: CampaignsMultiInfoGen;
  campaignMultiOriginal: CampaignsMultiInfoGen;
  analyseTypes = AnalyseTypes;
  listAnalyseTypes = _.values(this.analyseTypes);
  typeCampaigns: Type[];
  submitted: boolean = false;
  showSpinner: boolean = false;
  infoGenOk: boolean = true;
  initTime = performance.now();

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private campaignMultiService: MultiCampaignsService,
    private route: ActivatedRoute,
    private router: Router,
    private utilService: UtilService,
    private referenceType: ReferenceTypeService,
    private aiService: ApplicationInsightsService,
    private errorManager: ErrorManagerService
  ) {
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Multi Campaigns Details General Information', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.showSpinner = true;
    const campaignMultiId = this.route.parent.snapshot.paramMap.get('idCampaignMulti');
    this.campaignMultiService.getCampaignsMultiGeneralInfo(campaignMultiId).pipe(
      catchError(err => {
        this.infoGenOk = false;
        return this.onErrorRequest(err);
      }),
      tap(campaignMulti => this.campaignMulti = campaignMulti),
      tap(() => this.infoGenOk = true),
      tap(campaignMulti => this.campaignMultiOriginal = _.cloneDeep(campaignMulti)),
      tap(campaignMulti => this.campaignMultiService.hasNameChanged.next(campaignMulti.name)),
      finalize(() => this.showSpinner = false)
    ).subscribe();
    this.typeCampaigns = this.referenceType.getTypeCampaign();
  }

  isChangedCampaign() {
    return this.utilService.isDifferent(this.campaignMultiOriginal, this.campaignMulti);
  }

  byId(a: Type, b: Type): boolean {
    if (!b) return false;
    return a.id === b.id;
  }

  clickCancel() {
    this.campaignMulti = _.cloneDeep(this.campaignMultiOriginal);
  }

  onErrorRequest = (err: Response) => {
    this.showSpinner = false;
    this.errorManager.catchError(err);
    return observableThrowError(err);
  }

  save(form: NgForm) {
    this.showSpinner = true;
    this.submitted = true;
    if (form.valid) {
      const body = {
        id: this.campaignMulti.id,
        name: this.campaignMulti.name,
        description: this.campaignMulti.description,
        studyType: this.campaignMulti.studyType,
        analyseType: this.campaignMulti.analyseType,
        hub: this.campaignMulti.hub
      };
      this.campaignMultiService.putCampaignsMultiGeneralInfo(body).pipe(
        catchError(this.onErrorRequest),
        tap((CampaignsMultiInfoGen) => this.campaignMultiService.setCampaignMultiFromLocalStorage({
          id: CampaignsMultiInfoGen.id,
          name: CampaignsMultiInfoGen.name
        })),
        tap(() => this.campaignMultiService.hasNameChanged.next('')),
        tap(() => this.errorManager.displayMessage('ON_SUCCESS_UPDATE')),
        tap(() => this.utilService.updateMenuTabs()),
        tap(() => this.campaignMultiOriginal = _.cloneDeep(this.campaignMulti)),
        takeUntil(this.destroy$),
        finalize(() => {
          this.showSpinner = false;
        })
      )
        .subscribe();
    } else {
      this.errorManager.displayMessage('ON_ERROR_FORM', 'danger');
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.utilService.canDeactivate(this.campaignMulti, this.campaignMultiOriginal);
  }

}
