
import {of as observableOf, throwError as observableThrowError,
  Observable,
  throwError,
  of
} from 'rxjs';

import {mergeMap, catchError, tap, flatMap, finalize} from 'rxjs/operators';
import {
  ErrorManagerService
} from '../../../shared/services/errorManager.service';
import {
  VolunteerService
} from '../../volunteers.service';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  FicheRemanence,
  FicheRemanenceOptions,
  Panelist,
  Type,
  TimelineTypes
} from '../../../types';
import {
  AfterViewInit,
  Component,
  OnInit
} from '@angular/core';
import * as _ from 'lodash';
import { UserService } from '../../../shared/services/user.service';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

export enum TypeCosmetique {
  cosmetiqueCheveuxHumide = 'cosmetiqueCheveuxHumide',
  cosmetiqueCheveuxSec = 'cosmetiqueCheveuxSec'
}

export enum OptionCosmetiqueHumide {
  demelage = 'demelage',
  lisseToucher = 'lisseToucher',
  souplesse = 'souplesse',
  densite = 'densite',
  enrobage = 'enrobage'
}

export enum OptionCosmetiqueSec {
  demelage = 'demelage',
  lisseToucher = 'lisseToucher',
  souplesse = 'souplesse',
  densite = 'densite',
  enrobage = 'enrobage',
  facilitePassageDoigt = 'facilitePassageDoigt'
}

@Component({
  selector: 'dna-fiche-remanence',
  templateUrl: 'ficheRemanence.component.html',
  styleUrls: ['./ficheRemanance.less']
})

export class FicheRemanenceComponent implements OnInit, AfterViewInit {
  panelist: Panelist;
  showSpinner: boolean;
  ficheRemanence: FicheRemanence;
  ficheRemanenceOptions: FicheRemanenceOptions;
  isEditable: boolean = false;
  typeCosmetique: typeof TypeCosmetique = TypeCosmetique;
  optionCosmetiqueHumide: typeof OptionCosmetiqueHumide = OptionCosmetiqueHumide;
  optionCosmetiqueSec: typeof OptionCosmetiqueSec = OptionCosmetiqueSec;
  bridge: string;
  initTime = performance.now();

  constructor(
    private volunteerService: VolunteerService,
    private activatedRoute: ActivatedRoute,
    private errorManagerService: ErrorManagerService,
    private router: Router,
    private aiService: ApplicationInsightsService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.showSpinner = true;
    this.panelist = this.volunteerService.getPanelist();
    this.ficheRemanenceOptions = new FicheRemanenceOptions();
    this.activatedRoute.params.pipe(
      tap(p => this.bridge = _.get(p, 'bridgeNumber', '')),
      mergeMap(p => this.getFicheRemanence(p.idFiche, this.panelist.hub)),
      catchError(err => {
        this.goToGeneralInfo();
        this.errorManagerService.displayMessage(err.error.error, 'danger', {}, false);
        return observableThrowError(err);
      }),
      tap(ficheRemanence => this.ficheRemanence = ficheRemanence),
      tap(() => this.showSpinner = false),
      flatMap(() => this.activatedRoute.parent.paramMap)
      )
      .subscribe();
  }

  ngAfterViewInit() {
    const templateUrl = this.activatedRoute && this.activatedRoute.snapshot ? this.activatedRoute.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Volunteer Fiche Remanence', '', performance.now() - this.initTime, templateUrl);
  }

  getFicheRemanence(id: string, hub: string): Observable < FicheRemanence > {
    if (id === 'new') {
      this.isEditable = true;
      return observableOf(new FicheRemanence()).pipe(tap((f: FicheRemanence) => f.requestNumber = _.cloneDeep(this.bridge)));
    } else {
      this.isEditable = this.router.url.indexOf('/edit') !== -1;
      return this.volunteerService.getFicheRemanence(id, hub);
    }
  }

  changeValue(type: string) {
    if(this.isEditable){
      this.ficheRemanence[type] = !this.ficheRemanence[type];
    }
  }

  onChangeData(current: Type, hairType: string, option: string) {
    if(this.isEditable) this.ficheRemanence[hairType][option] = current;
  }

  isChecked(element: Type, hairType: string, option: string) {
    return (!this.ficheRemanence[hairType][option])
      ? false
      : this.ficheRemanence[hairType][option].id === element.id;
  }

  compareById(a: Type, b: Type) {
    return a && b && a.id == b.id;
  }

  save() {
    this.showSpinner = true;
    this.ficheRemanence.idVolunteer = _.get(this.panelist, "id", "");
    this.volunteerService.postFicheRemanence(this.panelist.panelistNumber, this.panelist.hub, this.ficheRemanence).pipe(
      catchError(err => {
        this.errorManagerService.displayMessage("UNKNOW_ERROR", "danger");
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      tap(fiche => {
        this.panelist.timeline.push(this.volunteerService.getFicheLight(fiche))
        this.volunteerService.setPanelist(this.panelist);
      }),
      tap(() => this.goToGeneralInfo()),
      finalize(() => this.showSpinner = false )
    ).subscribe();
  }

  goToGeneralInfo() {
    let path =['volunteers', this.panelist.panelistNumber, this.panelist.hub, 'generalInformation'];
    if (this.bridge) {
      path.push(this.bridge);
    }
    this.router.navigate(path);
  }
}
