import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FilterSchedule, KeyValue, Languages, Pager, TableHeader, Translation, TranslationsFiles, User, __Links } from '../../../types';
import { ReferenceTypeService } from '../../../shared/services/reference-type.service';
import { TranslationsService } from '../../../shared/services/translations.service';
import { tap, finalize, catchError, takeUntil, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Observable, forkJoin as observableForkJoin, of, Subject, forkJoin } from 'rxjs';
import { UtilService } from '../../../shared/services/util.service';
import { ErrorManagerService } from '../../../shared/services/errorManager.service';
import { DNATranslateService } from '../../../shared/services/translate.service';
import { FilterService } from '../../../shared/services/filter.service';
import { UserService } from '../../../shared/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dna-translations-management',
  templateUrl: './translations-management.component.html',
  styleUrls: ['./translations-management.component.less']
})
export class TranslationsManagementComponent implements OnInit, AfterViewInit {

  languages: Languages[];
  languagesSelected: Languages[];
  languagesInTable: Languages[];
  search: string;
  translationsFiles: TranslationsFiles;
  oldTranslationsFiles: TranslationsFiles;
  keysTraductions: string[];
  isChanged: boolean = false;
  showSpinner: boolean = true;
  modifiedKeys: {key: string, language: string}[] = [];
  actualLanguage: string = "";
  destroy$: Subject<boolean> = new Subject<boolean>();
  filter: any;
  user: User;
  tableHeaders: TableHeader[];
  __links: __Links;
  totalItems: number;
  error: boolean = false;
  initTime = performance.now();

  constructor(
    private cdr: ChangeDetectorRef,
    private referenceTypeService: ReferenceTypeService,
    private translationsService: TranslationsService,
    private utilService: UtilService,
    private translateService: DNATranslateService,
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private errorManager: ErrorManagerService,
    private filterService: FilterService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Profile Translations', '', performance.now() - this.initTime, templateUrl);
  }

  init() {
    this.showSpinner = true;
    this.user = this.userService.getUser();
    this.filter = this.filterService.getFilter().translations;
    this.actualLanguage = this.translateService.getLanguage();
    this.languagesSelected = [Languages.English];
    this.languagesInTable = [...this.languagesSelected];
    this.languages = this.referenceTypeService.getLanguages();
    this.buildTabHeader().pipe(
      tap(header => this.tableHeaders = header),
      mergeMap(() => this.translationsService.getFilteredTranslations(`'${this.languagesSelected}'`, _.get(this.filter, 'pageIndex', 1), _.get(this.filter, 'numberOfObjectsPerPage', 10))),
      tap(data => this.initPage(data)),
      catchError(err => {
        this.error = true;
        return this.catchError(err);
      }),
      finalize(() => {
        this.showSpinner = false;
        this.cdr.detectChanges();
      })
    ).subscribe();
  }

  initPage(data) {
    this.__links = data.__links;
    this.totalItems = data.totalItems;
    this.translationsFiles = this.translationsService.formatTranslations(data);
    this.translationsService.setTranslations(data.list);
    this.oldTranslationsFiles = _.cloneDeep(this.translationsFiles);
    this.keysTraductions = this.initKeyTrad(this.translationsFiles);
  }

  onParametersChanged(event: {pager: Pager, type: string}) {
    _.set(this.filter, 'pageIndex', _.get(event, 'pager.currentPage', 1));
    _.set(this.filter, 'numberOfObjectsPerPage', _.get(event, 'pager.pageSize', 1));
    return this.executeRequestByType(event, this.languagesSelected).pipe(
      tap(data => this.initPage(data)),
      catchError(this.catchError),
      finalize(() => {
        this.showSpinner = false;
        this.cdr.detectChanges();
      })
    ).subscribe();
  }

  private executeRequestByType(event: {pager: Pager, type: string}, languagesSelected: Languages[]) {
    if(event.type === 'itemsPerPage' || event.type === 'indexPage'){
      const langs = languagesSelected.map(l => `'${l}'`).join(',');
      return this.translationsService.getFilteredTranslations(langs, _.get(event.pager, 'currentPage', 1), _.get(event.pager, 'pageSize', 10), this.search || '');
    } else {
      return this.utilService.onTabParametersChanged(event.type, this.__links);
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.utilService.canDeactivate(this.oldTranslationsFiles, this.translationsFiles);
  }

  deleteSearch() {
    this.search = "";
    this.keysTraductions = this.initKeyTrad(this.translationsFiles);
  }

  getKeysSearch(language: object, search: string): string[] {
    return _.keys(language).filter(key => _.get(language, key, '').includes(search) || key.includes(search));
  }

  initKeyTrad(translationsFiles: TranslationsFiles): string[] {
    return _.uniq(this.languagesSelected.reduce((acc, lang) => {
      return acc.concat(Object.keys(translationsFiles[lang]));
    }, []));
  }

  onCancel() {
    this.modifiedKeys = [];
    this.isChanged = false;
    this.translationsFiles = _.cloneDeep(this.oldTranslationsFiles);
  }

  /**
   * Compare original value of translation with value in parameters and update
   * the table of "modifiedTranslations"
   * @param language
   * @param key
   * @param value
   */
  onChangeTranslation(event: {key: string, language: string}) {
    if (event.key.length && event.language.length && !this.modifiedKeys.find(e => e.key === event.key && e.language === event.language)) {
      this.modifiedKeys.push(event);
    }
    this.isChanged = this.modifiedKeys.length > 0;
  }

  onSave() {
    this.showSpinner = true;
    let isActualLanguageChanged = false;
    this.languages.forEach(language => {
      if (!_.isEqual(this.translationsFiles[language], this.oldTranslationsFiles[language])) {
        if (this.actualLanguage === language) {
          isActualLanguageChanged = true;
        }
      }
    });
    let observables: Observable<any>[] = [];
    this.modifiedKeys.map(keyLanguage => {
      let translation = this.translationsService.getTranslations().find(t => t.key === keyLanguage.key && t.language === keyLanguage.language);
      if (translation) {
        observables.push(this.translationsService.patchTranslation(translation.id, this.translationsFiles[keyLanguage.language][keyLanguage.key]));
      } else {
        const newTranslation: Translation = {
          key: keyLanguage.key,
          value: this.translationsFiles[keyLanguage.language][keyLanguage.key],
          language: keyLanguage.language
        }
        observables.push(this.translationsService.postTranslations(newTranslation));
      }
    });
    observableForkJoin(observables).pipe(
      catchError(this.catchError),
      takeUntil(this.destroy$),
      finalize(() => {
        this.oldTranslationsFiles = _.cloneDeep(this.translationsFiles);
        this.modifiedKeys = [];
        this.isChanged = false;
        this.showSpinner = false;
        this.cdr.detectChanges();
        this.errorManager.displayMessage('ON_SUCCESS_UPDATE');
      })
    ).subscribe(() => {
      if (isActualLanguageChanged) {
        this.translateService.updateTranslations(this.actualLanguage, this.translationsFiles[this.actualLanguage]);
      }
    });
  }

  /**
   * Search translations with keyword and selected languages
   * @param search
   * @param languagesSelected
   */
  searchKeyTrad(search: string = '', languagesSelected: Languages[]) {
    this.showSpinner = true;
    const langs = languagesSelected.map(l => `'${l}'`).join(',');
    this.buildTabHeader().pipe(
      tap(header => this.tableHeaders = header),
      mergeMap(() => this.translationsService.getFilteredTranslations(langs, 1, _.get(this.filter, 'numberOfObjectsPerPage', 10), search)),
      tap(data => this.initPage(data)),
      catchError(this.catchError),
      finalize(() => {
        this.languagesInTable= [...this.languagesSelected];
        this.showSpinner = false;
        this.cdr.detectChanges();
      })
    ).subscribe();
  }

  buildTabHeader(): Observable<TableHeader[]> {
    return forkJoin(this.utilService.translate(this.languagesSelected)).pipe(
      mergeMap(headerTranslations => {
        return this.utilService.createHeaderObject(headerTranslations, headerTranslations.map(ht => false), headerTranslations.map(ht => ''), headerTranslations);
      })
    )
  }

  catchError = (error) => {
    this.showSpinner = false;
    this.errorManager.catchError(error);
    this.errorManager.displayMessage("ON_ERROR_UPDATE", "danger");
    return of();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
