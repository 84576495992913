import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import { MultiCampaignsService } from '../../../multi-campaigns.service';
import { OnePagerService } from '../../../../shared/services/one-pager.service';
import {
  OnePager,
  PDFTranslation,
  CapturesData,
  FicheCaracterisation,
  CampaignsMulti,
  RoutineTabForReport,
  Campaign,
  Panelist,
  Evaluation,
  OnePagerPreviewMulti,
  CampaignMultiReferenceTested,
  SextiFormula,
  VisitsByVolunteer,
  Languages,
  OnePagerHeaderInfo, CampaignsMultiUsers, Resource, CampaignMultiTargetProtocol
} from '../../../../types';
import { Observable, of as observableOf, Subject, zip as observableZip, of, throwError } from 'rxjs';
import { ErrorManagerService } from '../../../../shared/services/errorManager.service';
import { catchError, tap, takeUntil, zip, mergeMap, flatMap, toArray, finalize, switchMap } from 'rxjs/operators';
import { GeneratePDFMultiService } from '../../../../shared/services/generate-pdf-multi.service';
import { CampaignService } from '../../../../campaigns/campaigns.service';
import { UserService } from '../../../../shared/services/user.service';
import { EvaluationService } from '../../../../campaigns/detail/edit-campaign/raw-data/evaluations.service';
import { environment } from '../../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  MilorAdditionalInfosMultiComponent
} from '../../../../shared/modals/milorAdditionalInfosMulti/milorAdditionalInfosMulti.component';
import { DNATranslateService } from '../../../../shared/services/translate.service';
import * as pdfFontsCJ from '../../../.././../inlineScripts/vfs_fonts_cj.js';
import { ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../../shared/services/applicationInsights.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dna-one-pager-multi-preview',
  templateUrl: './one-pager-multi-preview.component.html',
  styleUrls: ['./one-pager-multi-preview.component.less']
})
export class OnePagerMultiPreviewComponent implements OnInit, AfterViewInit {

  onePager: OnePager;
  idCampaignMulti: string;
  showSpinner: boolean;
  pdf: any;
  campaignsMulti: CampaignsMulti;
  imagesAndComments: { data: string }[] = [];
  campaignsOnePagers: Resource[];
  campaigns: Campaign[];
  panelists: Panelist[] = [];
  ficheCaracterisation: FicheCaracterisation[];
  translationsRoutines: object[][];
  routinesTab: RoutineTabForReport[][];
  tabReferenceTested: CampaignMultiReferenceTested;
  routines: SextiFormula[];
  visitsByVolunteerTab: {
    campaignName: string,
    visitsByVolunteer: VisitsByVolunteer[]
  }[] = [];
  currentLanguage: string;
  title = '';
  orchestraNumber = '';
  volunteersStarted: { [idCampaign: string]: string };
  evaluationsMulti: Evaluation[] = [];
  translations: PDFTranslation;
  headInfo;
  pdfReportUserByCol: boolean = false;
  initTime = performance.now();
  campaignMultiTarget: any; // TODO update types

  constructor(
    private campaignMultiService: MultiCampaignsService,
    private onePagerService: OnePagerService,
    private errorManagerService: ErrorManagerService,
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private generatePDFMultiService: GeneratePDFMultiService,
    private ref: ChangeDetectorRef,
    private userService: UserService,
    private evaluationService: EvaluationService,
    private campaignService: CampaignService,
    private modalService: NgbModal,
    private dnaTranslateService: DNATranslateService,
  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  destroy$: Subject<boolean> = new Subject<boolean>();

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Multi Campaigns Details One Pager Preview', '', performance.now() - this.initTime, templateUrl);
  }

  ngOnInit() {
    this.setPdfMakeFonts();
    this.showSpinner = true;
    this.onePager = this.onePagerService.getOnePager();
    this.idCampaignMulti = this.campaignMultiService.getCampaignMultiId();
    this.campaignMultiService.getCampaignMultiOnePagerTargetProtocol(this.idCampaignMulti).subscribe(res => { this.campaignMultiTarget = res },
      (error) => {
        console.error('Error fetching campaign multi target:', error);
      });
    const onePagerPreviewMulti$ = this.campaignMultiService.getCampaignMultiOnePagerPreview(this.idCampaignMulti).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
    const translationsFromPDF = (): Observable<PDFTranslation> => this.generatePDFMultiService.getTranslations();
    const imagesCaptures$: Observable<CapturesData[]> = this.onePagerService.getDataURI2(this.onePager.capturesUrls).pipe(
      catchError((err) => {
        return observableOf([]);
      }));

    onePagerPreviewMulti$.pipe(
      tap((data: OnePagerPreviewMulti) => {
        this.campaigns = data.campaigns;
        this.campaignsMulti = data.campaignsMulti;
        this.campaignsOnePagers = data.campaignsOnePagers;
        this.campaignsMulti.orchestra = this.campaigns.map(study => study.orchestra);
        this.title = data.title;
        this.orchestraNumber = data.orchestraNumber;
        this.campaigns.map(study => study.evaluations.map(el => el.studyId = study.id));
        this.evaluationsMulti = this.campaigns.reduce((acc, study) => acc.concat(study.evaluations), []);
        this.volunteersStarted = this.fillVolunteersTab(this.campaigns, this.evaluationsMulti);

        this.tabReferenceTested = this.formatCouples(this.onePagerService.getTestedReferenceTab(this.campaignsMulti, this.campaignMultiTarget.campaignsTarget ? this.campaignMultiTarget.campaignsTarget : []));
        this.ficheCaracterisation = data.ficheCaracterisation;
        this.routinesTab = data.routinesTab;
        this.routines = data.routines;
        this.visitsByVolunteerTab = data.visitsByVolunteerTab;
        this.headInfo = this.onePagerService.getOnePagerHeaderInfo();
        this.pdfReportUserByCol = data.pdfReportUserByCol;
      }),
      flatMap(() => this.getRoutinesTranslations(this.campaigns)),
      zip(imagesCaptures$, translationsFromPDF()),
      catchError(err => {
        this.errorManagerService.displayMessage('ON_ERROR_UPDATE', 'danger');
        return throwError(err);
      }),
      tap((args: [object[][], CapturesData[], PDFTranslation]) => {
        this.imagesAndComments = _.orderBy(args[1], 'index', 'asc');

        this.generatePDF(
          this.onePager,
          this.routinesTab,
          args[0],
          args[2],
          this.imagesAndComments,
          this.ficheCaracterisation,
          this.campaignsMulti,
          this.campaignsOnePagers,
          this.campaigns,
          this.tabReferenceTested,
          this.routines,
          this.visitsByVolunteerTab,
          this.volunteersStarted,
          this.headInfo,
          this.pdfReportUserByCol
        );

        this.dnaTranslateService.onLangChange().pipe(
          tap(() => this.setPdfMakeFonts()),
          mergeMap(() => this.getRoutinesTranslations(this.campaigns).pipe(zip(translationsFromPDF()))),
          takeUntil(this.destroy$))
          .subscribe((args2: [object[][], PDFTranslation]) => {
            this.generatePDF(
              this.onePager,
              this.routinesTab,
              args2[0],
              args2[1],
              this.imagesAndComments,
              this.ficheCaracterisation,
              this.campaignsMulti,
              this.campaignsOnePagers,
              this.campaigns,
              this.tabReferenceTested,
              this.routines,
              this.visitsByVolunteerTab,
              this.volunteersStarted,
              this.headInfo,
              this.pdfReportUserByCol || false
            );
          });
      }),
      finalize(() => {
        this.showSpinner = false;
        this.ref.detectChanges();
      })
    ).subscribe();
  }

  private generatePDF(
    onePager: OnePager,
    routinesTabForReport: any[],
    valuesFromRoutinesTranslations: object[][],
    translations: PDFTranslation,
    images,
    formsCaracterisation: FicheCaracterisation[],
    campaignsMulti: CampaignsMulti,
    campaignsOnePagers: Resource[],
    campaigns: Campaign[],
    tabReferenceTested: CampaignMultiReferenceTested,
    routines: SextiFormula[],
    visitsByVolunteerTab: any[],
    volunteersStarted: { [idCampaign: string]: string },
    headInfo: OnePagerHeaderInfo,
    pdfReportUserByCol: boolean,
  ) {
    const docDefinition = {
      info: {
        title: this.title
      },
      // a string or { width: number, height: number }
      pageSize: 'A4',
      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'portrait',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [15, 15],
      content: [],
      styles: {},
      defaultStyle: {
        columnGap: 5
      }
    };

    docDefinition.content = JSON.parse(JSON.stringify(this.generatePDFMultiService.getContent(
      pdfReportUserByCol,
      onePager,
      routinesTabForReport,
      valuesFromRoutinesTranslations,
      translations,
      images,
      formsCaracterisation,
      campaignsMulti,
      campaignsOnePagers,
      campaigns,
      tabReferenceTested,
      routines,
      visitsByVolunteerTab,
      volunteersStarted,
      headInfo
    )));
    docDefinition.styles = this.generatePDFMultiService.getStyles();
    this.pdf = pdfMake.createPdf(docDefinition);
  }

  downloadPdf() {
    this.pdf.download(this.campaignsMulti.name + ' - ' + new Date().toISOString() + '.pdf');
  }

  openPdf() {
    this.pdf.open();
  }

  sendToMilor() {
    const modal = this.modalService.open(MilorAdditionalInfosMultiComponent);
    modal.componentInstance.campaign = this.campaignsMulti;
    modal.componentInstance.metier = this.userService.getUser().currentWorkspace.name;
    modal.componentInstance.lang = this.userService.getUser().language;
    modal.componentInstance.title = this.title;
    modal.componentInstance.fieldwork = _.get(this.campaigns, '[0]fieldWork.id', '');
    modal.componentInstance.orchestraNumber = this.orchestraNumber;
    modal.componentInstance.recipients = this.onePager.archiveRecipients ? this.onePager.archiveRecipients.map(v => {
      return { 'display': v, 'value': v };
    }) : undefined;
    modal.result.then(
      (data) => {
        if (data.title && data.recipients) {
          this.onePager.archiveProjectTitle = data.title;
          this.onePager.archiveRecipients = data.recipients;
          this.pdf.getBase64(base64 => {
            this.postOnePagerToMilor(this.campaignsMulti.id, this.onePager.id, base64, data.title, data.recipients, this.userService.getUser().track);
          });
        }
      },
      () => {
      });
  }

  postOnePagerToMilor(idCampaign: string, idOnePager: string, base64: string, projectTitle: string, recipients: string[], peopleKey: string) {
    this.errorManagerService.displayMessage('Envoi en cours', 'warn');
    this.campaignService.postOnePagerToMilor(idCampaign, idOnePager, base64, projectTitle, recipients, peopleKey, []).pipe(
      catchError(err => {
        console.log(err);
        this.errorManagerService.displayMessage('UNKNOW_ERROR', 'danger');
        return throwError(err);
      }),
      tap(() => this.errorManagerService.displayMessage('ON_SUCCESS'))
    ).subscribe((milorData: any) => {
      if (milorData.documentCurrentId) {
        this.onePager.archiveId = milorData.documentCurrentId;
      }
    });
  }

  goToMilorPlatform() {
    window.open(environment.milor_url() + this.onePager.archiveId);
  }

  getRoutinesTranslations(campaigns: Campaign[]): Observable<object[][]> {
    const observableRoutines: Observable<object[]>[] = [];
    campaigns.forEach(campaign => {
      observableRoutines.push(this.onePagerService.translateKeysFromRoutines(campaign.formula.routines, _.get(campaign, 'metier.name', undefined)));
    });
    return observableRoutines.length === 0 ? observableOf([]) : observableZip(...observableRoutines);
  }

  getRoutines(campaigns: Campaign[]): Observable<RoutineTabForReport[][]> {
    const observableRoutines: Observable<RoutineTabForReport[]>[] = [];

    campaigns.forEach(campaign => {
      const tabQuantitiesFromCampaign = _.get(campaign, 'parameters.isRoutine', false)
        ? this.onePagerService.getQuantitiesFromCampaign(_.get(campaign, 'workflows', []), _.get(campaign, 'formula.routines', []), _.get(campaign, 'metier.name', undefined), _.get(campaign, 'users.volunteers', []))
        : [];
      const newRoutinesTab$ = !_.get(campaign, 'parameters.isRoutine', false)
        ? of(tabQuantitiesFromCampaign)
        : of(this.evaluationsMulti.filter(e => e.studyId === campaign.id)).pipe(
          tap((evals: Evaluation[]) => this.visitsByVolunteerTab.push({
            campaignName: campaign.name,
            visitsByVolunteer: this.onePagerService.buildVisiteByVolunteerTable(evals, campaign, this.currentLanguage)
          })),
          flatMap((evals: Evaluation[]) => this.onePagerService.updateQuantitiesWithEvals(evals, tabQuantitiesFromCampaign, _.get(campaign, 'evaluations', []))),
          toArray()
        );
      observableRoutines.push(newRoutinesTab$);
    });
    return observableRoutines.length === 0 ? observableOf([]) : observableZip(...observableRoutines);
  }

  getEvaluationsMulti(campaignsId: string[]): Observable<Evaluation[]> {
    const obs: Observable<Evaluation[]>[] = [];
    campaignsId.forEach(id => {
      obs.push(
        this.evaluationService.getEvaluationsPerCampaign(id).pipe(
          catchError(err => {
            return of([]);
          }),
          tap(evals => this.evaluationsMulti = this.evaluationsMulti.concat(evals))
        )
      );
    });
    return obs.length === 0 ? observableOf([]) : observableZip(...obs);
  }

  private setPdfMakeFonts() {
    this.currentLanguage = this.dnaTranslateService.getLanguage();
    if (this.currentLanguage === Languages.Chinese || this.currentLanguage === Languages.Japanese) {
      pdfMake.vfs = pdfFontsCJ.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: '方正黑体简体.TTF',
          bold: '方正黑体简体.TTF',
          italics: '方正黑体简体.TTF',
          bolditalics: '方正黑体简体.TTF',
        },

        方正黑体简体: {
          normal: '方正黑体简体.TTF',
          bold: '方正黑体简体.TTF',
          italics: '方正黑体简体.TTF',
          bolditalics: '方正黑体简体.TTF',
        },
        defaultStyle: {
          font: '方正黑体简体'
        }
      };
    } else {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: 'Roboto-Regular.ttf',
          bold: 'Roboto-Medium.ttf',
          italics: 'Roboto-Italic.ttf',
          bolditalics: 'Roboto-MediumItalic.ttf',
        },
        defaultStyle: {
          font: 'Roboto'
        }
      };
    }
  }


  /**
   * On récupère le numéro orchestra commun entre les différentes campagnes.
   * S'ils sont différents ou tous vide, on renvoie une chaine vide
   * @returns
   */


  fillVolunteersTab(campaigns: Campaign[], evaluationsMulti: Evaluation[]) {
    const volunteersStarted = {};
    campaigns.forEach(c => {
      const evaluationsStarted = evaluationsMulti.filter(e => e.studyId === c.id && _.get(e, 'progress', 0) > 0);

      const volunteersNumber = _.get(c, 'users.volunteersNumber', 0);
      const volunteersStartedNumber = _.get(c, 'users.volunteers', []).filter(v => !_.isEmpty(evaluationsStarted.filter(e => e.volunteer.name === v.name))).length;
      volunteersStarted[c.id] = volunteersStartedNumber + '/' + volunteersNumber;
    });
    return volunteersStarted;
  }

  formatCouples(tab: CampaignMultiReferenceTested) {
    let finalCouples = [];
    tab.couples.forEach(couple => {
      if (couple.tested.includes(',')) {
        let valuesTested = couple.tested.split(',');
        valuesTested.forEach(vt => {
          let newCouple = _.cloneDeep(couple);
          newCouple.tested = vt.trim();
          finalCouples.push(newCouple);
        })
      } else {
        finalCouples.push(couple)
      }
    })
    tab.couples = finalCouples;
    return tab;
  }
}
