import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MultiCampaignsService } from '../../multi-campaigns.service';
import { catchError, tap, finalize } from 'rxjs/operators';
import { of as observableOf, Subject, Observable, throwError, of } from 'rxjs';
import { FormulasMulti, AnalyseTypes, CampaignFormulasMulti, Routine2, ChosenBenchRoutines, TypeApplicationMode, TypeDryingType, GroupedFormula, AnalyseType, ChosenFormulas } from '../../../types';
import * as _ from 'lodash';
import { ErrorManagerService } from '../../../shared/services/errorManager.service';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

interface ActiveRoutines extends Routine2 {
  campaignId?: string;
  campaignName?: string;
  index?: number;
}

type RoutineSelectionTable = {
  [routineName: string]: boolean;
}

type SelectedRoutine = {
  index: number,
  routineName: string,
  routineId: string,
  isActiv: boolean
}

type RoutineInfos = {
  id: string,
  name: string,
  label: string
}

class RoutinesMulti {
  routineName: {
    [idEtude: string]: string;
  };
  visits: VisitsMulti[];

  constructor() {
    this.routineName = {};
    this.visits = [];
  }
}

class VisitsMulti {
  visitName: {
    [idEtude: string]: string;
  };
  orders: OrdersMulti[];
  constructor() {
    this.visitName = {};
    this.orders = [];
  }
}

class OrdersMulti {
  orderName: {
    [idEtude: string]: string;
  };
  steps: StepsMulti[];
  constructor() {
    this.orderName = {};
    this.steps = [];
  }
}

class StepsMulti {
  stepName: {
    [idEtude: string]: string;
  };
  formulaName: {
    [idEtude: string]: string;
  };
  lot: {
    [idEtude: string]: string;
  };
  pauseTime: {
    [idEtude: string]: PauseTime;
  };
  productName: {
    [idEtude: string]: string;
  };
  quantity: {
    [idEtude: string]: string;
  };
  applicators: {
    [idEtude: string]: string;
  };
  dryingTypes: {
    [idEtude: string]: string;
  };
  constructor() {
    this.stepName = {};
    this.formulaName = {};
    this.lot = {};
    this.pauseTime = {};
    this.productName = {};
    this.quantity = {};
    this.applicators = {};
    this.dryingTypes = {};
  }
}

class PauseTime {
    hour: number;
    minute: number;
    second: number;
    oldValue: string;
    isOldFormat: boolean;

    constructor(hour, minute, second, oldValue, isOldFormat) {
      this.hour = hour;
      this.minute = minute;
      this.second = second;
      this.oldValue = oldValue;
      this.isOldFormat = isOldFormat;
    }
}

@Component({
  selector: 'dna-formulas-multi',
  templateUrl: './formulas-multi.component.html',
  styleUrls: ['./formulas-multi.component.less']
})

export class FormulasMultiComponent implements OnInit, AfterViewInit {

  isChange: boolean = false;
  error: boolean;
  showSpinner: boolean = false;
  idMultiCampaign: string;
  analyseType: AnalyseTypes;
  campaignFormulas: FormulasMulti[];
  routineFormulas: {
    [idCampaign: string]: RoutineInfos[];
  };
  chosenBench: ChosenBenchRoutines;
  chosenRoutines: ChosenBenchRoutines;
  chosenFormulas: ChosenFormulas[];
  previousChosenRoutines: ChosenBenchRoutines;
  routinesSelectionTable: RoutineSelectionTable = {};
  activesRoutinesTable: ActiveRoutines[] = [];
  widthTable: number;
  routinesComparisonTable: RoutinesMulti;
  listAnalyseTypes = _.values(AnalyseTypes);
  campaignMultiFormulas: CampaignFormulasMulti;
  firstCall: boolean = true;
  originalCampaignMultiFormulas: CampaignFormulasMulti;
  isRoutines: boolean = true;
  nbRoutines: number; //nombre de routines sélectionnées dans le formulaire
  nbRoutinesMax: number = 0; //nombre de routine maximum possible en concaténation (exemple études avec 2R, 3R et 4R : nbRoutinesMax = 2)
  nbRoutinesTab: number[] = []; //liste utilisée dans le formulaire pour choisir le nombre de routines crées à partir du nbRoutinesMax (exemple [1, 2])
  initTime = performance.now();

  constructor(
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private multiCampaignsService: MultiCampaignsService,
    private errorManager: ErrorManagerService
  ) { }

  destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Multi Campaigns Details Formula', '', performance.now() - this.initTime, templateUrl);
  }

  cancel() {
    this.campaignMultiFormulas = _.cloneDeep(this.originalCampaignMultiFormulas);
    this.analyseType = this.originalCampaignMultiFormulas.analyseType;
    this.init();
    this.isChange = false;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.isChange) return true;
    else {
      this.errorManager.displayMessage('SAVE_MODIFICATION_BEFORE_CONTINUING', "default");
      return false;
    }
  }

  isShown(data: { [idEtude: string]: string }) {
    let bool = false;
    Object.keys(data).map(k => { if (data[k]) bool = true });
    return bool;
  }

  hasRoutines(campaignFormulasMulti: CampaignFormulasMulti): boolean {
    return _.get(campaignFormulasMulti, 'campaignsFormulas[0].isRoutine', true);
  }

  /**
   * Cette fonction permet de créer un objet de routines ou de formules qui sera utilisé pour créer le tableau
   * récapitulatif des routines/formules
   * @param nbRoutine nombre de routines min
   * @param formulasMulti liste des formules
   */
  formatRoutinesFormulas(formulasMulti: FormulasMulti[]) {
    let routineFormula = {};
    formulasMulti.forEach(fm => {
      let routineFormTab = [];
      for (let i = 0; i < fm.formula.listFormulas.length; i++) {
        const routineName = fm.formula.listFormulas.filter(f => f.name == 'R' + (i + 1));
        const rf = !_.isEmpty(routineName)
          ? routineName[0]
          : fm.formula.listFormulas[i];
        !_.isNil(rf) || !_.isEmpty(rf)
          ? routineFormTab.push({
            id: rf.id,
            name: rf.name,
            label: !_.isEmpty(routineName) ? this.getLabelRoutine(fm.formula.routines, rf.name) : ""
          })
          : routineFormTab.push({});
      }
      routineFormula[fm.id] = routineFormTab;
    });
    return routineFormula;
  }

  /**
   * Calcule le nombre de routine max à partir de chaque étude pour déterminer le nombre de colonnes du tableau
   * récapitulatif de formules/routines
   * @param formulasMulti
   */
  getNbRoutine(nbRoutinesMin: number): number[] {
    let array = [];
    for (let i = 1; i <= nbRoutinesMin; i++) {
      array.push(i);
    }
    return array;
  }

  getLabelRoutine(listeRoutines: Routine2[], name: string): string {
    const routine = listeRoutines.filter(rout => rout.name === name);
    return _.isEmpty(routine) ? "" : _.get(routine[0], 'label', '');
  }

  getCampaignMultiFormulas(): Observable<CampaignFormulasMulti> {
    const campaignMultiId = this.route.parent.snapshot.paramMap.get('idCampaignMulti');
    this.firstCall = !this.campaignMultiFormulas;
    return this.campaignMultiFormulas ? of(this.campaignMultiFormulas) : this.multiCampaignsService.getCampaignMultiFormulas(campaignMultiId);
  }

  onChangeAnalyseType() {
    this.isChange = true;
    this.init();
  }

  init() {
    this.error = false;
    this.showSpinner = true;
    this.getCampaignMultiFormulas().pipe(
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        return throwError(err);
      }),
      tap((data: CampaignFormulasMulti) => this.campaignMultiFormulas = data),
      tap((data: CampaignFormulasMulti) => this.idMultiCampaign = data.idMultiCampaign),
      tap((data: CampaignFormulasMulti) => this.analyseType = this.analyseType ? this.analyseType : data.analyseType),
      tap((data: CampaignFormulasMulti) => this.isRoutines = this.hasRoutines(data)),
      tap((data: CampaignFormulasMulti) => this.campaignFormulas = data.campaignsFormulas),
      tap((data: CampaignFormulasMulti) => this.nbRoutines = data.nbRoutines),
      tap((data: CampaignFormulasMulti) => this.nbRoutinesTab = this.getNbRoutine(data.nbRoutinesMin)),
      tap((data: CampaignFormulasMulti) => this.nbRoutinesMax = data.nbRoutinesMin),
      tap((data: CampaignFormulasMulti) => this.chosenFormulas = this.initChosenFormulas(this.analyseType, data.chosenFormulas, data.campaignsFormulas)),
      tap((data: CampaignFormulasMulti) => this.chosenBench = this.initChosenBench(this.analyseType, data.chosenBench, data.campaignsFormulas)),
      tap((data: CampaignFormulasMulti) => this.chosenRoutines = this.initChosenRoutines(this.analyseType, data.chosenRoutines, data.campaignsFormulas, this.nbRoutinesMax)),
      tap((data: CampaignFormulasMulti) => this.routineFormulas = this.formatRoutinesFormulas(data.campaignsFormulas)),
      tap((data: CampaignFormulasMulti) => { if (this.firstCall) this.originalCampaignMultiFormulas = _.cloneDeep(data) }),
      tap((data: CampaignFormulasMulti) => this.multiCampaignsService.hasNameChanged.next(data.campaignMultiName)),
      tap(() => this.previousChosenRoutines = _.cloneDeep(this.chosenRoutines)),
      tap(() => this.activesRoutinesTable = []),
      tap(() => this.initRoutinesSelectionTable()),
      tap(() => this.initActivesRoutinesTable(this.isRoutines, this.campaignFormulas)),
      tap(() => this.formatTable()),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  calculWidthTable(activesRoutinesTable) {
    this.widthTable = ((activesRoutinesTable.length) + 1) * 200;
  }

  formatTable() {
    if (!_.isEmpty(this.activesRoutinesTable)) {
      setTimeout(() => {
        const firstColumnElements = document.getElementsByClassName("first-column");
        for (let i = 0; i < firstColumnElements.length; i++) {
          firstColumnElements[i].setAttribute('height', (firstColumnElements[i].nextElementSibling.clientHeight + 3).toString());
        }
      });
    }
  }

    /**
   * Cette méthode permet d'initialiser chosenBench (l'objet qui sera mis à jour dans la multiEtude).
   * Si le type d'analyse est la comparaison, on initialise l'objet à vide.
   * @param analyseType
   * @param chosenBench
   * @param campaignsFormulas
   */
     initChosenBench(analyseType: string, chosenBench: ChosenBenchRoutines, campaignsFormulas: FormulasMulti[]): ChosenBenchRoutines {
      let updateMultiEtude = false;
      if (analyseType == AnalyseTypes.comparison) {
        if (!chosenBench) {
          chosenBench = {};
        }
        campaignsFormulas.forEach(cf => {
          let bench = _.get(cf, 'formula.chosenBench.id');
          if (!bench) bench = _.get(cf, 'formula.listFormulas[0].id');
          if (!chosenBench[cf.id]) {
            chosenBench[cf.id] = {};
            if (bench) {
              chosenBench[cf.id].bench = bench;
              updateMultiEtude = true;
            };
          }
        });
      }
      if (updateMultiEtude) {
        this.save(chosenBench, undefined, undefined, undefined, false);
      }
      return chosenBench;
    }

    /**
     * Cette méthode permet d'initialiser chosenRoutines (l'objet qui sera mis à jour dans la multiEtude).
     * Si le type d'analyse est la concaténation, on initialise chaque routine avec la routine
     * correspondante dans l'étude. Et on sauvegarde le chosenRoutines dans la multiEtude (ne concerne que
     * les routines, pas les formules)
     * @param analyseType
     * @param chosenRoutines
     * @param campaignsFormulas
     */
    initChosenRoutines(analyseType, chosenRoutines: ChosenBenchRoutines, campaignsFormulas: FormulasMulti[], nbRoutines: number): ChosenBenchRoutines {
      if (analyseType == AnalyseTypes.concatenation) {
        let updateMultiEtude = false;
        if (!chosenRoutines) {
          chosenRoutines = {};
        }
        campaignsFormulas.forEach(cf => {
          if (!chosenRoutines[cf.id]) {
            updateMultiEtude = true;
            chosenRoutines[cf.id] = {};
            for (let i = 0; i < cf.formula.listFormulas.length; i++) {
              const routine = cf.formula.listFormulas.filter(f => f.name === 'R' + (i + 1));
              chosenRoutines[cf.id]['R' + (i + 1)] = _.isEmpty(routine) ? cf.formula.listFormulas[i].id : routine[0].id;
            }
          }
        });
        if (updateMultiEtude) {
          this.save(undefined, chosenRoutines, undefined, nbRoutines, false);
        }
      }
      return chosenRoutines;
    }

    initChosenFormulas(analyseType, chosenFormulas: ChosenFormulas[], campaignsFormulas: FormulasMulti[]) {
      if (!chosenFormulas) {
        chosenFormulas = [];
      }
      if (analyseType == AnalyseTypes.comparisonBareSkin) {
        campaignsFormulas.forEach(campaign => {
          let campaignExists = chosenFormulas.find(cf => cf.idCampaign === campaign.id);
          if (!campaignExists) {
            campaignExists = {
              idCampaign: campaign.id,
              formulas: []
            }
            chosenFormulas.push(campaignExists);
          }
          _.get(campaign, 'formula.listFormulas', []).forEach(formula => {
            const formulaExists = campaignExists.formulas.find(f => f.id === formula.id);
            if (!formulaExists) {
              campaignExists.formulas.push({
                id: formula.id,
                isActive: true
              });
            }
          })
        })
      }
      return chosenFormulas;
    }

    getCampaignName(idCampaign) {
      return _.get(this.campaignFormulas.find(c => c.id === idCampaign), 'name', "")
    }

    getFormulaName(idCampaign, idFormula) {
      const campaign = this.campaignFormulas.find(c => c.id === idCampaign);
      const formula = campaign ? _.get(this.campaignFormulas.find(c => c.id === idCampaign), 'formula.listFormulas', []).find(f => f.id === idFormula) : {};
      return _.get(formula, 'name', '');
    }


  /**
   * On initialise la table de sélection des routines
   * Toutes les routines sont désactivées à l'initialisation sauf la 1ere par défaut
   */
  private initRoutinesSelectionTable() {
    for (let i = 0; i < this.nbRoutines; i++) {
      this.routinesSelectionTable['R' + (i + 1)] = false;
    }
  }

  /**
   * Initialisation du tableau des routines actives
   * Mode comparaison :
   *  Chaque routine sélectionnée par défaut dans les listes déroulantes est ajoutée dans le tableau des routines actives
   * Mode Concaténation :
   *  Chaque routine sélectionnée par défaut dans les listes déroulantes
   *  de la colonne active est ajoutée dans le tableau des routines actives
   */
  private initActivesRoutinesTable(isRoutines: boolean, campaignFormulas: FormulasMulti[]) {
    if (isRoutines) {
      this.routinesSelectionTable['R1'] = true;
      campaignFormulas.forEach((cf, index) => {
        let routine = this.analyseType === AnalyseTypes.comparison
          ? _.values(this.chosenBench[cf.id])[0]
          : this.chosenRoutines[cf.id]["R1"];
        let routineSelected = cf.formula.listFormulas.find(lf => lf.id === routine);
        routineSelected.index = index;
        this.selectRoutine(cf, routineSelected);
      });
      this.updateRoutinesMultiTab(this.activesRoutinesTable);
    }
  }

  onclickHead(index) {
    this.initRoutinesSelectionTable();
    this.routinesSelectionTable[`R${index + 1}`] = true;
    this.activesRoutinesTable = [];
    this.campaignFormulas.forEach(cf => {
      let routineSelected = cf.formula.listFormulas.find(lf => lf.id === this.chosenRoutines[cf.id][`R${index + 1}`]);
      if (routineSelected) {
        routineSelected.index = index;
        this.selectRoutine(cf, routineSelected);
      }
    })
    this.updateRoutinesMultiTab(this.activesRoutinesTable);
  }

  onChangeValue(formulaMulti: FormulasMulti, idRoutine: string, index: number) {
    this.isChange = true;
    // comparaison
    if (this.analyseType === AnalyseTypes.comparison) {
      let routine = formulaMulti.formula.listFormulas.find(f => f.id === idRoutine);
      routine.index = index;
      this.selectRoutine(formulaMulti, routine);
      this.updateRoutinesMultiTab(this.activesRoutinesTable);
    } else {
      if (_.get(formulaMulti, 'formula.listFormulas').length > 1) {
        let listeRoutines = this.chosenRoutines[formulaMulti.id];
        let previous = this.previousChosenRoutines[formulaMulti.id]['R' + (index + 1)];
        _.forOwn(listeRoutines, function (value, key) {
          if (key !== 'R' + (index + 1)) {
            if (value === idRoutine) {
              listeRoutines[key] = previous;
            }
          }
        });
        this.previousChosenRoutines = _.cloneDeep(this.chosenRoutines);
        this.onclickHead(index);
      }
    }
  }

  private applyValues(campaignFormulaFiltered: ActiveRoutines[],
    currentRoutine: RoutinesMulti,
    currentVisit: VisitsMulti,
    currentOrder: OrdersMulti,
    currentStep: StepsMulti,
    indexVisit: number,
    indexOrder: number,
    indexStep: number,
    elementType: string) {
    campaignFormulaFiltered.map(routine => {
      switch (elementType) {
        case 'routine':
          currentRoutine.routineName[routine.campaignId] = routine.name;
          break;
        case 'visit':
          currentVisit.visitName[routine.campaignId] = _.get(routine, ['visits', indexVisit, 'name'], '').toString();
          break;
        case 'order':
          currentOrder.orderName[routine.campaignId] = _.get(routine, ['visits', indexVisit, 'orders', indexOrder, 'name'], '').toString();
          break;
        case 'step':
          const pauseTime = _.get(routine, ['visits', indexVisit, 'orders', indexOrder, 'steps', indexStep, 'formula', 'pauseTime'], '');
          currentStep.stepName[routine.campaignId] = _.get(routine, ['visits', indexVisit, 'orders', indexOrder, 'steps', indexStep, 'name'], '').toString();
          currentStep.formulaName[routine.campaignId] = _.get(routine, ['visits', indexVisit, 'orders', indexOrder, 'steps', indexStep, 'formula', 'formulaName'], '').toString();
          currentStep.lot[routine.campaignId] = _.get(routine, ['visits', indexVisit, 'orders', indexOrder, 'steps', indexStep, 'formula', 'lot'], '').toString();
          if (_.isNil(pauseTime)) {
            currentStep.pauseTime[routine.campaignId] = new PauseTime(0, 0, 0, 0, false);
          } else if (_.has(pauseTime, 'hour')){
            currentStep.pauseTime[routine.campaignId] = new PauseTime(pauseTime.hour, pauseTime.minute, pauseTime.second, 0, false);
          } else {
            currentStep.pauseTime[routine.campaignId] = new PauseTime(0, 0, 0, pauseTime, true);
          }
          currentStep.productName[routine.campaignId] = _.get(routine, ['visits', indexVisit, 'orders', indexOrder, 'steps', indexStep, 'formula', 'productName'], '').toString();
          currentStep.quantity[routine.campaignId] = _.isNil(_.get(routine, ['visits', indexVisit, 'orders', indexOrder, 'steps', indexStep, 'formula', 'quantity'], ''))
            ? ''
            : _.get(routine, ['visits', indexVisit, 'orders', indexOrder, 'steps', indexStep, 'formula', 'quantity'], '').toString();
          let applicators = _.get(routine, ['visits', indexVisit, 'orders', indexOrder, 'steps', indexStep, 'formula', 'applicators'], []);
          currentStep.applicators[routine.campaignId] = _.isArray(applicators)
            ? applicators.reduce((acc, curr, index) => {
                return index === applicators.length - 1 ? acc.concat(curr.value) : acc.concat(curr.value.concat(', '))
              }, '')
            : '';
          let dryingTypes = _.get(routine, ['visits', indexVisit, 'orders', indexOrder, 'steps', indexStep, 'formula', 'dryingTypes'], []);
          currentStep.dryingTypes[routine.campaignId] = dryingTypes.reduce((acc, curr, index) => {
            return index === dryingTypes.length - 1 ? acc.concat(curr.value) : acc.concat(curr.value.concat(', '))
          }, '');
          break;
      }
    });
  }

  private getMaxVisits(routines: ActiveRoutines[]): number {
    let max = 0;
    routines.map(r => {
      let length = _.get(r, 'visits', []).length;
      if (length > max) max = length;
    })
    return max;
  }

  private getMaxOrders(routines, currentVisitIndex): number {
    let max = 0;
    routines.map(r => {
      let length = _.get(r, ['visits', currentVisitIndex, 'orders'], []).length;
      if (length > max) max = length;
    })
    return max;
  }

  private getMaxSteps(routines, currentVisitIndex, currentOrderIndex): number {
    let max = 0;
    routines.map(r => {
      let length = _.get(r, ['visits', currentVisitIndex, 'orders', currentOrderIndex, 'steps'], []).length;
      if (length > max) max = length;
    })
    return max;
  }

  onClickRoutine(cf: FormulasMulti, infosRoutine: RoutineInfos) {
    if (this.isRoutines && !this.isEmptyRoutine(infosRoutine)) {
      let routine = cf.formula.listFormulas.find(f => f.id === infosRoutine.id);
      this.selectRoutine(cf, routine);
      this.updateRoutinesMultiTab(this.activesRoutinesTable);
    }
  }

  isEmptyRoutine(infosRoutine) {
    return _.isEmpty(infosRoutine);
  }

  isActive(indexHead) {
    return this.routinesSelectionTable['R' + (indexHead + 1)];
  }

  selectRoutine(formulaMulti: FormulasMulti, routineSelected: GroupedFormula) {
    this.updateActivesRoutinesTable(formulaMulti, routineSelected);
    this.updateRoutinesMultiTab(this.activesRoutinesTable);
  }

  private updateActivesRoutinesTable(formulaMulti: FormulasMulti, routineSelected: GroupedFormula) {
    this.activesRoutinesTable = this.activesRoutinesTable.filter(routine => {
      return (routine.campaignName !== formulaMulti.name);
    });
    let routine = formulaMulti.formula.listFormulas.find(f => f.id === routineSelected.id);
    let routineObject: ActiveRoutines = formulaMulti.formula.routines.find(r => r.name === routine.name);
    _.set(routineObject, 'campaignId', formulaMulti.id);
    _.set(routineObject, 'campaignName', formulaMulti.name);
    _.set(routineObject, 'index', routineSelected.index);
    this.activesRoutinesTable.push(routineObject);
    this.activesRoutinesTable = _.orderBy(this.activesRoutinesTable, 'index', 'asc');
  }

  private updateRoutinesMultiTab(activesRoutinesTable: ActiveRoutines[]) {
    if (this.isRoutines) {
      this.generateRoutinesMultiTab(activesRoutinesTable);
    }
    this.calculWidthTable(activesRoutinesTable);
    this.formatTable();
  }

  /**
 * création du tableau de comparaison des routines
 * @param campaignFormulaFiltered
 */
  private generateRoutinesMultiTab(activesRoutinesTable: ActiveRoutines[]) {
    let maxVisits = this.getMaxVisits(activesRoutinesTable);
    this.routinesComparisonTable = new RoutinesMulti();
    this.applyValues(activesRoutinesTable, this.routinesComparisonTable, null, null, null, null, null, null, 'routine');
    for (let i = 0; i < maxVisits; i++) {
      const currentVisit = new VisitsMulti();
      this.applyValues(activesRoutinesTable, null, currentVisit, null, null, i, null, null, 'visit');
      let maxOrders = this.getMaxOrders(activesRoutinesTable, i);
      for (let j = 0; j < maxOrders; j++) {
        const currentOrder = new OrdersMulti();
        this.applyValues(activesRoutinesTable, null, null, currentOrder, null, i, j, null, 'order');
        let maxSteps = this.getMaxSteps(activesRoutinesTable, i, j);
        for (let k = 0; k < maxSteps; k++) {
          const currentStep = new StepsMulti();
          this.applyValues(activesRoutinesTable, null, null, null, currentStep, i, j, k, 'step');
          currentOrder.steps.push(currentStep);
        }
        currentVisit.orders.push(currentOrder);
      }
      this.routinesComparisonTable.visits.push(currentVisit);
    };
  }

  save(chosenBench: ChosenBenchRoutines, chosenRoutines: ChosenBenchRoutines, chosenFormulas: ChosenFormulas[], nbRoutines: number, showConfirmModal: boolean = true) {
    this.showSpinner = true;
    let valuesChosen: { analyseType: AnalyseType, nbRoutines?: number, chosenRoutines?: ChosenBenchRoutines, chosenBench?: ChosenBenchRoutines, chosenFormulas?: ChosenFormulas[]} = { analyseType: this.analyseType };
    if (this.analyseType === AnalyseTypes.concatenation) {
      valuesChosen.nbRoutines = nbRoutines;
      valuesChosen.chosenRoutines = chosenRoutines;
    } else if (this.analyseType === AnalyseTypes.comparison) {
      valuesChosen.chosenBench = chosenBench;
    } else {
      valuesChosen.chosenFormulas = chosenFormulas;
    }

    this.multiCampaignsService.patchCampaignMultiFormulas(this.idMultiCampaign, valuesChosen).pipe(
      catchError(() => {
        this.showSpinner = false;
        if (showConfirmModal) this.errorManager.displayMessage('ON_ERROR_UPDATE', "danger");
        return observableOf(undefined);
      }),
      tap((data) => this.originalCampaignMultiFormulas = data as any),
      tap(() => this.originalCampaignMultiFormulas.campaignsFormulas = this.campaignFormulas),
      tap(() => this.isChange = false),
      tap(() => { if (showConfirmModal) this.errorManager.displayMessage('ON_SUCCESS_UPDATE') }),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }


  changeFormula(formulas, campaign, formula) {
    formulas[campaign].formulas[formula].isActive = !formulas[campaign].formulas[formula].isActive;
    this.isChange = true;
  }

}
