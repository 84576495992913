import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as _ from 'lodash';

import { Block, BlockParameter, DNAComponent, WorkflowConfiguration } from '@app/types';
import { DNATranslateService } from '@app/shared/services/translate.service';
import { ApplicationInsightsService } from '@app/shared/services/applicationInsights.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'dna-questions-activate',
  templateUrl: './questions-activate.component.html',
  styles: ['label {cursor: pointer}'],
  styleUrls: ['./questions-activate.component.less']
})
export class QuestionsActivateComponent implements OnInit, AfterViewInit {

  @Input() workflowConfiguration: WorkflowConfiguration;
  @Input() isWorkflowEditable: boolean;
  @Output() change: EventEmitter<WorkflowConfiguration> = new EventEmitter();

  initialComponentsSelectedByBlock: number[] = [];
  componentsSelectedByBlock: number[] = [];
  currentLanguage: string;
  initTime = performance.now();

  constructor(
    private dnaTranslateService: DNATranslateService,
    private aiService: ApplicationInsightsService,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.currentLanguage = this.dnaTranslateService.getLanguage();
    this.componentsSelectedByBlock = this.initComponentsSelectedArray(this.workflowConfiguration.blocks);
    this.initialComponentsSelectedByBlock = _.cloneDeep(this.componentsSelectedByBlock);
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Workflows Questions Activate', '', performance.now() - this.initTime, templateUrl);
  }

  initComponentsSelectedArray(blocks: Block[]): number[] {
    const componentsSelectedByBlock = [];
    blocks.forEach(
      (block: Block, index: number) => {
        if (!block.parameters) {
          block.parameters = new BlockParameter();
        }
        componentsSelectedByBlock.push(0);
        block.components.forEach(
          (component: DNAComponent) => {
            if (!component.args.inactive) {
              componentsSelectedByBlock[index] += 1;
            }
          }
        );
      }
    );
    return componentsSelectedByBlock;
  }

  updateInactiveQuestionToBlock(targetComponent: DNAComponent, block: Block, index: number) {
    if (this.isWorkflowEditable) {
      this.componentsSelectedByBlock[index] = targetComponent.args.inactive ? this.componentsSelectedByBlock[index] + 1 :
        this.componentsSelectedByBlock[index] - 1;
      targetComponent.args.inactive = !targetComponent.args.inactive;
      block.parameters.inactive = !block.components.find((component: DNAComponent) => !component.args.inactive);
      this.workflowConfiguration.isChangedActivecomponent = this.isChangedWorkflow();
      this.change.emit(this.workflowConfiguration);
    }
  }

  updateInactiveBlockToQuestion(block: Block, index: number, event: any) {
    event.stopPropagation();
    if (this.isWorkflowEditable) {
      this.componentsSelectedByBlock[index] = block.parameters.inactive ? block.components.length : 0;
      block.parameters.inactive = !block.parameters.inactive;
      block.components = block.components.map(component => _.assignIn(component, {args: _.assignIn(component.args, {inactive: block.parameters.inactive})}));
      this.workflowConfiguration.isChangedActivecomponent = this.isChangedWorkflow();
      this.change.emit(this.workflowConfiguration);
    }
  }

  isChangedWorkflow() {
    return !_.isEqual(this.componentsSelectedByBlock, this.initialComponentsSelectedByBlock);
  }

}
