import {CampaignService} from '../../../campaigns/campaigns.service';

import {FilterService} from '../../../shared/services/filter.service';
import {ErrorManagerService} from '../../../shared/services/errorManager.service';
import {UtilService} from '../../../shared/services/util.service';
import {DNATranslateService} from '../../../shared/services/translate.service';

import {ModalContent, LightCampaign, Translatable, FilterSchedule, CampaignsMulti, IdValue} from '../../../types';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MultiCampaignsService} from '../../multi-campaigns.service';
import {tap, mergeMap, catchError, take, map, finalize, filter} from 'rxjs/operators';
import {throwError, Observable, of, merge, from} from 'rxjs';

import * as _ from 'lodash';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbdModalConfirmMultiStudyName} from './confirm-name-modal/confirm-name-modal.component';
import { ApplicationInsightsService } from '../../../shared/services/applicationInsights.service';

interface Study {
  id: string;
  name: string;
  workflows?: string;
  formulas?: string;
  synergy?: string;
  arcs?: string;
  isRoutine?: any;
}

@Component({
  selector: 'dna-studies-multi',
  templateUrl: './studies-multi.component.html',
  styleUrls: ['./studies-multi.component.less']
})
export class StudiesMultiComponent implements OnInit, AfterViewInit {
  showSpinner: boolean;
  showSpinnerCampaigns: boolean;
  error: boolean;
  idMultiCampaign: string;
  multiCampaign: CampaignsMulti;
  campaigns: Study[] = [];
  filter: FilterSchedule;
  initTime = performance.now();

  constructor(
    private route: ActivatedRoute,
    private multiCampaignsService: MultiCampaignsService,
    private translateService: DNATranslateService,
    private errorManagerService: ErrorManagerService,
    private aiService: ApplicationInsightsService,
    private utilService: UtilService,
    private campaignService: CampaignService,
    private campaignMultiService: MultiCampaignsService,
    private filterService: FilterService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.loadStudies();
    this.filter = _.cloneDeep(this.filterService.getFilterMulti());
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Multi Campaigns Details Studies', '', performance.now() - this.initTime, templateUrl);
  }

  loadStudies(): void {
    this.showSpinner = true;
    this.error = false;
    this.route.parent.paramMap.pipe(
      map(param => param.get('idCampaignMulti')),
      mergeMap(idCampaign => this.multiCampaignsService.getMultiCampaignStudies(idCampaign)),
      tap(multiC => this.addStringToStudy(multiC)),
      tap(multiC => this.multiCampaign = multiC),
      tap(multiC => this.campaignMultiService.hasNameChanged.next(multiC.name)),
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        return throwError(err);
      }),

      tap(() => this.showSpinner = false),
      take(1)
    ).subscribe();
  }


  /**
   * Add workflows, formulas, synergy numbers and arcs numbers as string to the study object
   * @param multiCampaign
   */
  addStringToStudy(multiCampaign: CampaignsMulti) {
    multiCampaign.studies.map((study: Study) => {
      study.workflows = this.workflowsOfMultiToString(multiCampaign, study.id);
      study.formulas = this.formulasOfMultiToString(multiCampaign, study.id);
      study.synergy = this.keysValueOfMultiToString(multiCampaign, study.id, 'synergy');
      study.arcs = this.keysValueOfMultiToStringArcs(multiCampaign, study.id, 'arcs');
    });
  }

  workflowsOfMultiToString(multiCampaign: CampaignsMulti, id: string): string {
    const filteredWks = multiCampaign.workflows.filter(wk => wk.idCampaign === id);
    return this.toStringWorkflows(filteredWks);
  }

  toStringWorkflows(wks: { name: Translatable }[]): string {
    const toStringMethod = wk => this.translateService.getTranslation(wk.name, this.translateService.getLanguage());
    return this.reduceToString(wks, toStringMethod);
  }

  formulasOfMultiToString(multiCampaign: CampaignsMulti, id: string): string {
    const filteredFormulas = multiCampaign.formulas.filter(f => f.idCampaign === id);
    return this.toStringFormulas(filteredFormulas);
  }

  toStringFormulas(formulas: { name: string }[]): string {
    const toStringMethod = f => f.name;
    return this.reduceToString(formulas, toStringMethod);
  }

  keysValueOfMultiToString(multiCampaign: CampaignsMulti, id: string, key: string): string {
    const array = multiCampaign[key].filter(el => el.studyId == id);
    return array[0];
  }

  keysValueOfMultiToStringArcs(multiCampaign: CampaignsMulti, id: string, key: string): string {
    const array = multiCampaign[key].filter((d: { [key: string]: string }) => d[id] !== undefined);
    return this.toStringKeyValue(array);
  }

  toStringKeyValue(array: IdValue[]): string {
    const toStringMethod = d => d[Object.keys(d)[0]];
    return this.reduceToString(array, toStringMethod);
  }

  reduceToString<T>(arrayToReduce: Array<T> = [], toStringMethod: (data: T) => string, separator: string = ' / '): string {
    return arrayToReduce.reduce((string, d, index) => {
      if (index > 0) { string += separator; }
      string += toStringMethod(d);
      return string;
    }, '');
  }

  searchCampaign(): void {
    this.campaigns = [];
    this.showSpinnerCampaigns = true;
    this.campaignService.getHttpCampaignsWithFilter(this.filter).pipe(
      map(result => result.list.filter(c => c.isTemplate !== true)),
      map(campaigns => this.fromLightCampaignToStudy(campaigns)),
      tap(campaigns => this.campaigns = this.filterCampaignsNotInMulti(campaigns)),
      finalize(() => this.showSpinnerCampaigns = false),
      take(1)
    ).subscribe();
  }

  fromLightCampaignToStudy(campaigns: LightCampaign[]): Study[] {
    return campaigns.map(c => {
      return {
        name: c.name,
        id: c.id,
        workflows: this.toStringWorkflows(c.workflows),
        formulas: this.toStringFormulas(c.formulas),
        synergy: _.get(c, 'synergy.requestNumber', ''),
        isRoutine: _.get(c, 'parameters.isRoutine', '')
      };
    });
  }

  filterCampaignsNotInMulti(campaigns: Study[]): Study[] {
    return campaigns.filter(c => !this.multiCampaign.studies.find(multiC => multiC.id === c.id));
  }

  removeStudy(study: Study): void {
    this.utilService.translateMessageModal('CONFIRM_DELETE', study.name, 'THE_CAMPAIGN').pipe(
      mergeMap((modalContent: ModalContent) => this.utilService.openModalConfirm(modalContent)),
      mergeMap(() => this.deleteCampaignStudy(this.multiCampaign.id, study.id)),
      take(1),
      tap(() => this.errorManagerService.displayMessage('ON_SUCCESS_UPDATE')))
      .subscribe();
  }

  /** Ajout d'une étude à la multi-étude
   * Si l'étude contient un numéro Synergy, on demande si l'utilisateur souhaite mettre à jour le nom de la multi-étude
   */
  addStudyToMulti(study: Study): void {
    const studyHasSynergy$: Observable<boolean> = of(study.synergy).pipe(
      filter(b => _.get(b, 'length', 0) > 0),
      mergeMap(() => this.openModal(study))
    );
    const studyHasnotSynergy$: Observable<boolean> = of(study.synergy).pipe(
      filter(b => _.get(b, 'length', 0) === 0),
      map(() => false)
    );

    merge(studyHasSynergy$, studyHasnotSynergy$).pipe(
      take(1),
      tap(() => this.showSpinner = true),
      mergeMap(isNameUpdated => this.multiCampaignsService.addCampaignStudy(this.multiCampaign.id, study.id, isNameUpdated)),
      tap(() => this.resetSearch()),
      tap(multiC => this.onAddUpdateMultiCampaign(multiC)),
      catchError(err => {
        this.errorManagerService.displayMessage('UNKNOW_ERROR', 'danger');
        return throwError(err);
      }),
      tap(() => this.errorManagerService.displayMessage('ON_SUCCESS_ADD_STUDY_TO_MULTI')),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  }

  resetSearch(): void {
    this.campaigns = [];
    this.filter.name = '';
  }


  onAddUpdateMultiCampaign(multiC: CampaignsMulti): CampaignsMulti {
    this.campaignMultiService.setCampaignMultiFromLocalStorage({
      id: multiC.id,
      name: multiC.name,
    });
    this.campaignMultiService.hasNameChanged.next(multiC.name);
    this.multiCampaign = multiC;
    this.addStringToStudy(this.multiCampaign);
    return this.multiCampaign;
  }

  openModal(study: Study): Observable<boolean> {
    const modal = this.modalService.open(NgbdModalConfirmMultiStudyName);
    modal.componentInstance.oldName = this.multiCampaign.name;
    modal.componentInstance.newSynergy = study.synergy;
    return from(modal.result);
  }

  deleteCampaignStudy(idMulti: string, id: string): Observable<CampaignsMulti> {
    this.showSpinner = true;
    return this.multiCampaignsService.deleteCampaignStudy(idMulti, id).pipe(
      catchError(err => {
        this.showSpinner = false;
        this.errorManagerService.displayMessage('UNKNOW_ERROR', 'danger');
        return throwError(err);
      }),
      tap(data => this.campaignMultiService.hasNameChanged.next(data.name)),
      tap(data => this.multiCampaign.name = data.name),
      tap(() => this.showSpinner = false),
      tap(() => this.multiCampaign.studies = this.multiCampaign.studies.filter(c => c.id !== id))
    );
  }
}
