import { takeUntil, tap, catchError, flatMap } from 'rxjs/operators';
import {
  ActivatedRoute,
  ParamMap,
  Router
} from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';
import { Languages } from '../../../edit-campaign/analyse/reports/chart-module/enums';
import { Subject, throwError, of } from 'rxjs';
import {
  CampaignService
} from '../../../../campaigns.service';
import { DNATranslateService } from '../../../../../shared/services/translate.service';
import { StudyTimepoint, StudyTimepointVolunteer, Timepoint, ValuesTimepoint } from '../../../../../types';
import { ApplicationInsightsService } from '../../../../../shared/services/applicationInsights.service';

@Component({
  selector: 'dna-photos',
  templateUrl: 'photos.component.html',
  styleUrls: ['./photos.component.less']
})

export class PhotosComponent implements OnInit, OnDestroy, AfterViewInit {

  idCampaign: string = '';
  campaign: StudyTimepoint;
  description: string;
  isDisabled: boolean;
  showSpinner: boolean = false;
  error: boolean = false;
  timepoints: any[] = [];
  volunteersId: string[] = [];
  valuesData: { [volunteerId: string]: ValuesTimepoint } = {}
  orderAsc: boolean = true;
  volunteerCharacters: string = '';
  originVolunteersId: string[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  showDefaultLanguage: boolean = false;
  public Languages = Languages;
  currentLanguage: string;
  initTime = performance.now();

  constructor(
    private campaignService: CampaignService,
    private route: ActivatedRoute,
    private aiService: ApplicationInsightsService,
    private router: Router,
    private DNATranslate: DNATranslateService,
  ) {
    this.currentLanguage = this.DNATranslate.getLanguage();
    this.DNATranslate.onLangChange().subscribe(({ lang }: any) => {
      this.currentLanguage = lang;
    });
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit() {
    const templateUrl = this.route && this.route.snapshot ? this.route.snapshot.routeConfig.path : '';
    this.aiService.logPageView('Campaign Photos', '', performance.now() - this.initTime, templateUrl);
  }

  displayEnglishLanguage(dataByLanguage: any) {
    if (!dataByLanguage[this.currentLanguage]) {
      return true
    }
    return false
  }
  init() {
    this.error = false;
    this.showSpinner = true;

    this.route.parent.paramMap.pipe(
      tap((params: ParamMap) => this.idCampaign = params.get('idCampaign')),
      flatMap((params: ParamMap) => this.campaignService.getCampaignTimepoints(params.get('idCampaign'))),
      catchError(err => {
        this.error = true;
        this.showSpinner = false;
        throwError(err);
        return of(undefined);
      }),
      tap((data: StudyTimepointVolunteer) => {
        if (data && Object.keys(data).length) {
          this.campaign = data.study;
          this.campaignService.setRandoVolunteers(data.randomisationVolunteers)
          this.campaignService.updateBreadCrumb(data.study.name);//pour update nav studyName
          this.campaignService.setStudyPartial(data.study);
          this.buildDataTable(data);
        }
      }),
      tap(() => this.showSpinner = false),
      takeUntil(this.destroy$),)
      .subscribe(() => { });
  }

  buildDataTable(data: StudyTimepointVolunteer) {
    const { timepoints, volunteers, values } = data;
    this.campaignService.setTimepointsReference(timepoints)
    this.timepoints = timepoints.map(({ id, name }: Timepoint) => {
      return {
        "name": name,
        "icon": "",
        "sortable": false,
        "id": id
      }
    });
    this.originVolunteersId = volunteers;
    this.volunteersId = this.originVolunteersId;

    if (!values || Object.keys(values).length === 0) {
      this.volunteersId.forEach((volunteerId) => {
        values[volunteerId] = {}
      })
    }
    this.valuesData = values;
    this.campaignService.setTimepointsData(this.valuesData);
  }

  getValueTimepointByVolunteer(volunteerId, timepointId) {
    const dataVolunteer = this.valuesData[volunteerId]
    return dataVolunteer && dataVolunteer[timepointId] ? dataVolunteer[timepointId].referencesPhoto.length : 0
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onSortByChanged() {
    this.orderAsc = !this.orderAsc;
    this.volunteersId.sort((a: string, b: string) => {
      if (this.orderAsc) {
        return a.localeCompare(b);
      } else {
        return b.localeCompare(a);
      }
    })
  }

  getFilterVolunteer() {
    if (this.volunteerCharacters.trim() !== "") {
      this.volunteersId = this.originVolunteersId.filter((volunteerId) => volunteerId.includes(this.volunteerCharacters));
    } else {
      this.volunteersId = this.originVolunteersId;
    }
  }

  addDefaultTranslate(event) {
    this.showDefaultLanguage = event

  }
  goToPageVolunteerPhotos(volunteerId: string) {
    this.campaignService.setVolunteersId(this.originVolunteersId);
    // TO CHECK OR TO DO : set data in localStorage?? for persistant
    this.router.navigate(['campaigns', this.idCampaign, 'edit', 'photos', 'volunteer', volunteerId]);
  }

  redirect(timepointId) {
    this.campaignService.setVolunteersId(this.originVolunteersId);
    console.log("timepointId:", timepointId)
    this.router.navigate(['campaigns', this.idCampaign, 'edit', 'photos', 'timepoint', timepointId]);
  }
}
